/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ClientProvisioningInitialEmailRequest } from '../models/client-provisioning-initial-email-request';
import { ClientSftpInfo } from '../models/client-sftp-info';
import { PasswordResetEmailRequest } from '../models/password-reset-email-request';

@Injectable({
  providedIn: 'root',
})
export class ClientSftpProvisioningService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation passwordResetEmailV2
   */
  static readonly PasswordResetEmailV2Path = '/v2/provisioning/passwordreset/email';

  /**
   * Send password reset email.
   *
   * Send password reset email
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `passwordResetEmailV2()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  passwordResetEmailV2$Response(params: {
    body: PasswordResetEmailRequest
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, ClientSftpProvisioningService.PasswordResetEmailV2Path, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * Send password reset email.
   *
   * Send password reset email
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `passwordResetEmailV2$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  passwordResetEmailV2(params: {
    body: PasswordResetEmailRequest
  }): Observable<string> {

    return this.passwordResetEmailV2$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation provisioningGetV2
   */
  static readonly ProvisioningGetV2Path = '/v2/provisioning/{entityId}';

  /**
   * Get client SFTP details.
   *
   * Returns client SFTP provisioning details.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `provisioningGetV2()` instead.
   *
   * This method doesn't expect any request body.
   */
  provisioningGetV2$Response(params: {

    /**
     * EntityId of the client to get the sftp info for.
     */
    entityId: string;
  }): Observable<StrictHttpResponse<ClientSftpInfo>> {

    const rb = new RequestBuilder(this.rootUrl, ClientSftpProvisioningService.ProvisioningGetV2Path, 'get');
    if (params) {
      rb.path('entityId', params.entityId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClientSftpInfo>;
      })
    );
  }

  /**
   * Get client SFTP details.
   *
   * Returns client SFTP provisioning details.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `provisioningGetV2$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  provisioningGetV2(params: {

    /**
     * EntityId of the client to get the sftp info for.
     */
    entityId: string;
  }): Observable<ClientSftpInfo> {

    return this.provisioningGetV2$Response(params).pipe(
      map((r: StrictHttpResponse<ClientSftpInfo>) => r.body as ClientSftpInfo)
    );
  }

  /**
   * Path part for operation provisioningPostV2
   */
  static readonly ProvisioningPostV2Path = '/v2/provisioning';

  /**
   * Checks to see if client has already been provisioned. Creates ClientFileIngressProvisioning table record. Send initial email to client to register SFTP account.
   *
   * Returns client SFTP provisioning details.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `provisioningPostV2()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  provisioningPostV2$Response(params: {
    body: ClientProvisioningInitialEmailRequest
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, ClientSftpProvisioningService.ProvisioningPostV2Path, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * Checks to see if client has already been provisioned. Creates ClientFileIngressProvisioning table record. Send initial email to client to register SFTP account.
   *
   * Returns client SFTP provisioning details.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `provisioningPostV2$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  provisioningPostV2(params: {
    body: ClientProvisioningInitialEmailRequest
  }): Observable<string> {

    return this.provisioningPostV2$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

}
