import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, pairwise } from 'rxjs/operators';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RouterExtService {
  private _previousUrl: Subject<string | null> = new BehaviorSubject(null);
  public previousUrl = this._previousUrl.asObservable();
  private _currentUrl: Subject<string | null> = new BehaviorSubject(null);
  currentUrl = this._currentUrl.asObservable();

  constructor(router: Router) {
    // On first load we need the current location setup
    this._currentUrl.next(window.location.pathname);
    // Always grab the latest routes on every navigation event
    router.events
      .pipe(
        filter((e): e is NavigationEnd => e instanceof NavigationEnd),
        pairwise()
      )
      .subscribe(([prev, curr]) => {
        const prevQuery = prev.urlAfterRedirects.indexOf('?');
        const prevUrl = prev.urlAfterRedirects.substring(0, prevQuery !== -1 ? prevQuery : undefined);
        const currQuery = curr.urlAfterRedirects.indexOf('?');
        const currUrl = curr.urlAfterRedirects.substring(0, currQuery !== -1 ? currQuery : undefined);
        this._previousUrl.next(prevUrl);
        this._currentUrl.next(currUrl);
      });
  }
}
