import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ApplicationInsights, IExceptionTelemetry } from '@microsoft/applicationinsights-web';
import { filter } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ApplicationInsightsService {
  private appInsights: ApplicationInsights;

  constructor(private router: Router) {
    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: environment.appInsights.instrumentationKey,
      },
    });

    this.appInsights.loadAppInsights();
    this.loadCustomTelemetryProperties();
    this.createRouterSubscription();
  }

  setUserId(userId: string) {
    this.appInsights.setAuthenticatedUserContext(userId);
  }

  clearUserId() {
    this.appInsights.clearAuthenticatedUserContext();
  }

  logPageView(name?: string, uri?: string) {
    this.appInsights.trackPageView({ name, uri });
  }

  logTrace(message: string, severityLevel?: number) {
    this.appInsights.trackTrace({ message, severityLevel });
  }

  logException(error: Error) {
    const exception: IExceptionTelemetry = {
      exception: error,
    };

    this.appInsights.trackException(exception);
  }

  private loadCustomTelemetryProperties() {
    this.appInsights.addTelemetryInitializer((envelope) => {
      const item = envelope.baseData;
      item.properties = item.properties || {};
      item.properties.ApplicationPlatform = 'WEB';
      item.properties.ApplicationName = 'Credit Manager UI';
    });
  }

  private createRouterSubscription() {
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      this.logPageView(null, event.urlAfterRedirects);
    });
  }
}
