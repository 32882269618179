import { Injectable } from '@angular/core';
import { AuthService as Auth0Service } from '@auth0/auth0-angular';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import { BehaviorSubject, Observable, of, timer } from 'rxjs';
import { catchError, map, scan, tap, switchMap, distinctUntilChanged, filter, shareReplay } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

export const IDLE_TIMEOUT: number = 60; // In seconds

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public user$ = this.authService.user$.pipe(
    filter((u) => u !== undefined && u !== null),
    shareReplay(1)
  );

  // Returns the issuedAt field or undefined
  // issuedAt is emitted when authenticated and it has changed
  // undefined is emitted when unauthenticated
  private _sessionEnds: BehaviorSubject<number | undefined> = new BehaviorSubject<number | undefined>(undefined);
  public sessionEnds$ = this._sessionEnds.asObservable().pipe(distinctUntilChanged());
  public sessionIdleStart$ = this.sessionEnds$.pipe(
    map((end) => (end === undefined ? undefined : end - IDLE_TIMEOUT * 1000)),
    distinctUntilChanged()
  );

  constructor(private authService: Auth0Service) {
    // Whenever isAuthenticated changes, we need to handle appropriatly
    this.authService.isAuthenticated$.subscribe((authenticated) => {
      if (authenticated) {
        // We just logged in, so we are active
        this.refresh();
      } else {
        // There isn't a user so we are logged out
        this._sessionEnds.next(undefined);
      }
    });

    // Handle if other tabs make changes to local storage
    window.onstorage = (event$) => {
      if (event$.key !== 'cm-session-end') {
        return;
      }
      if (event$.newValue === null) {
        this.logout();
      } else {
        this.refresh();
      }
    };

    // Auto Logout after the session has ended
    this.sessionEnds$
      .pipe(
        switchMap((sessionEnds) => {
          if (sessionEnds === undefined) {
            return of(false);
          }
          const remaining = sessionEnds - Date.now();
          return timer(remaining).pipe(
            map(() => {
              return true;
            })
          );
        }),
        filter((ended) => ended)
      )
      .subscribe((ended) => {
        if (ended) {
          this.logout();
        }
      });
  }

  // Passthrough to logout with the appropriate logoutUrl
  logout() {
    this.sendSessionEnds(null);
    this.authService.logout({ returnTo: environment.auth.logoutUrl });
  }

  // Passthrough to getAccessTokenSilently
  getAccessTokenSilently(): Observable<string | undefined> {
    return this.authService.getAccessTokenSilently().pipe(
      scan(
        (acc: { current: string | null; previous: string | null }, current: string | null) => {
          return {
            previous: acc.current,
            current,
          };
        },
        { current: null, previous: null }
      ),
      tap(({ previous, current }) => {
        if (previous !== current) {
          this.refresh();
        }
      }),
      map(({ current }) => current ?? undefined),
      catchError((err) => {
        console.error(`Could not get access token: ${err}`);
        return of(undefined);
      })
    );
  }

  refresh() {
    this.authService
      .getAccessTokenSilently()
      .pipe(
        catchError((error) => {
          console.error(`Could not get access token: ${error}`);
          return of(undefined);
        }),
        map((accessToken) => {
          if (accessToken === undefined) {
            return undefined;
          }
          const claims = jwtDecode<JwtPayload>(accessToken);
          if (claims.iat === undefined) {
            console.error("Access Token doesn't have an iat claim.");
            return undefined;
          }
          return (claims.iat + environment.idleTimeout) * 1000;
        })
      )
      .subscribe((sessionEnds) => {
        if (sessionEnds !== undefined) {
          this.sendSessionEnds(sessionEnds);
          this._sessionEnds.next(sessionEnds);
        }
      });
  }

  private sendSessionEnds(sessionEnds: number | null) {
    localStorage.setItem('cm-session-end', JSON.stringify(sessionEnds));
  }
}
