/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CalculationSegment } from '../models/calculation-segment';
import { CreateCreditCalculationRequest } from '../models/create-credit-calculation-request';
import { CreateCreditCalculationResponse } from '../models/create-credit-calculation-response';
import { GetCreditCalculationStrategiesResponse } from '../models/get-credit-calculation-strategies-response';
import { GetCreditCalculationsQueueSettingsRequest } from '../models/get-credit-calculations-queue-settings-request';
import { GetCreditCalculationsQueueSettingsResponse } from '../models/get-credit-calculations-queue-settings-response';
import { ListClientCalculationOptionsResponse } from '../models/list-client-calculation-options-response';
import { ListCreditCalculationsResponse } from '../models/list-credit-calculations-response';
import { PostCreditCalculationOptionResponse } from '../models/post-credit-calculation-option-response';
import { PutCreditCalculationOptionRequest } from '../models/put-credit-calculation-option-request';
import { SetCreditCalculationRequest } from '../models/set-credit-calculation-request';
import { SetCreditCalculationsQueueSettingsRequest } from '../models/set-credit-calculations-queue-settings-request';

@Injectable({
  providedIn: 'root',
})
export class CreditCalculationService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation cancelCreditCalculationV2
   */
  static readonly CancelCreditCalculationV2Path = '/v2/creditCalculations/{id}/cancel';

  /**
   * Cancels a queued credit calculation.
   *
   * Allows for cancelling an queued credit calculation
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `cancelCreditCalculationV2()` instead.
   *
   * This method doesn't expect any request body.
   */
  cancelCreditCalculationV2$Response(params: {

    /**
     * Unit of Work ID for the credit calculation
     */
    id: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, CreditCalculationService.CancelCreditCalculationV2Path, 'post');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Cancels a queued credit calculation.
   *
   * Allows for cancelling an queued credit calculation
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `cancelCreditCalculationV2$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  cancelCreditCalculationV2(params: {

    /**
     * Unit of Work ID for the credit calculation
     */
    id: string;
  }): Observable<void> {

    return this.cancelCreditCalculationV2$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation listCreditCalculationsV2
   */
  static readonly ListCreditCalculationsV2Path = '/v2/creditCalculations';

  /**
   * Get a list of credit calculations.
   *
   * Returns a list of credit calculations based on sorting, paging, and filtering rules.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listCreditCalculationsV2()` instead.
   *
   * This method doesn't expect any request body.
   */
  listCreditCalculationsV2$Response(params?: {

    /**
     * Which field to sort by
     */
    sort?: 'unitOfWorkStatus' | 'startTime';

    /**
     * Order ascending or descending
     */
    order?: 'asc' | 'desc';

    /**
     * How big each page should be (Default: &#x27;20&#x27;)
     */
    pageSize?: number;

    /**
     * Search on the results by name, identifier, or fein
     */
    search?: string;

    /**
     * Defines how to filter the result
     */
    filter?: 'active' | 'canceled' | 'completed';

    /**
     * Id to use to get the previous page
     */
    beforeId?: string;

    /**
     * Id to get the next page
     */
    afterId?: string;
  }): Observable<StrictHttpResponse<ListCreditCalculationsResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CreditCalculationService.ListCreditCalculationsV2Path, 'get');
    if (params) {
      rb.query('sort', params.sort, {});
      rb.query('order', params.order, {});
      rb.query('pageSize', params.pageSize, {});
      rb.query('search', params.search, {});
      rb.query('filter', params.filter, {});
      rb.query('beforeId', params.beforeId, {});
      rb.query('afterId', params.afterId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ListCreditCalculationsResponse>;
      })
    );
  }

  /**
   * Get a list of credit calculations.
   *
   * Returns a list of credit calculations based on sorting, paging, and filtering rules.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `listCreditCalculationsV2$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listCreditCalculationsV2(params?: {

    /**
     * Which field to sort by
     */
    sort?: 'unitOfWorkStatus' | 'startTime';

    /**
     * Order ascending or descending
     */
    order?: 'asc' | 'desc';

    /**
     * How big each page should be (Default: &#x27;20&#x27;)
     */
    pageSize?: number;

    /**
     * Search on the results by name, identifier, or fein
     */
    search?: string;

    /**
     * Defines how to filter the result
     */
    filter?: 'active' | 'canceled' | 'completed';

    /**
     * Id to use to get the previous page
     */
    beforeId?: string;

    /**
     * Id to get the next page
     */
    afterId?: string;
  }): Observable<ListCreditCalculationsResponse> {

    return this.listCreditCalculationsV2$Response(params).pipe(
      map((r: StrictHttpResponse<ListCreditCalculationsResponse>) => r.body as ListCreditCalculationsResponse)
    );
  }

  /**
   * Path part for operation createCreditCalculationV2
   */
  static readonly CreateCreditCalculationV2Path = '/v2/creditCalculations';

  /**
   * Create a new credit calculation.
   *
   * Allows for creating a new credit calculation
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createCreditCalculationV2()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createCreditCalculationV2$Response(params?: {
    body?: CreateCreditCalculationRequest
  }): Observable<StrictHttpResponse<CreateCreditCalculationResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CreditCalculationService.CreateCreditCalculationV2Path, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CreateCreditCalculationResponse>;
      })
    );
  }

  /**
   * Create a new credit calculation.
   *
   * Allows for creating a new credit calculation
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createCreditCalculationV2$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createCreditCalculationV2(params?: {
    body?: CreateCreditCalculationRequest
  }): Observable<CreateCreditCalculationResponse> {

    return this.createCreditCalculationV2$Response(params).pipe(
      map((r: StrictHttpResponse<CreateCreditCalculationResponse>) => r.body as CreateCreditCalculationResponse)
    );
  }

  /**
   * Path part for operation getCreditCalculationStrategies
   */
  static readonly GetCreditCalculationStrategiesPath = '/v2/creditCalculations/strategies';

  /**
   * Get Credit Calculation Strategies.
   *
   * Get Credit Calculation Strategies
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCreditCalculationStrategies()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCreditCalculationStrategies$Response(params?: {
  }): Observable<StrictHttpResponse<GetCreditCalculationStrategiesResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CreditCalculationService.GetCreditCalculationStrategiesPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GetCreditCalculationStrategiesResponse>;
      })
    );
  }

  /**
   * Get Credit Calculation Strategies.
   *
   * Get Credit Calculation Strategies
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCreditCalculationStrategies$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCreditCalculationStrategies(params?: {
  }): Observable<GetCreditCalculationStrategiesResponse> {

    return this.getCreditCalculationStrategies$Response(params).pipe(
      map((r: StrictHttpResponse<GetCreditCalculationStrategiesResponse>) => r.body as GetCreditCalculationStrategiesResponse)
    );
  }

  /**
   * Path part for operation listCalculationSegments
   */
  static readonly ListCalculationSegmentsPath = '/v2/creditCalculations/{unitOfWorkId}/segments';

  /**
   * Get a list of calculation segments.
   *
   * Returns a list of calculation segments.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listCalculationSegments()` instead.
   *
   * This method doesn't expect any request body.
   */
  listCalculationSegments$Response(params: {

    /**
     * Unit of work id for the calculation.
     */
    unitOfWorkId: string;
  }): Observable<StrictHttpResponse<Array<CalculationSegment>>> {

    const rb = new RequestBuilder(this.rootUrl, CreditCalculationService.ListCalculationSegmentsPath, 'get');
    if (params) {
      rb.path('unitOfWorkId', params.unitOfWorkId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CalculationSegment>>;
      })
    );
  }

  /**
   * Get a list of calculation segments.
   *
   * Returns a list of calculation segments.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `listCalculationSegments$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listCalculationSegments(params: {

    /**
     * Unit of work id for the calculation.
     */
    unitOfWorkId: string;
  }): Observable<Array<CalculationSegment>> {

    return this.listCalculationSegments$Response(params).pipe(
      map((r: StrictHttpResponse<Array<CalculationSegment>>) => r.body as Array<CalculationSegment>)
    );
  }

  /**
   * Path part for operation listClientCalculationOptions
   */
  static readonly ListClientCalculationOptionsPath = '/v2/creditCalculations/options';

  /**
   * Get the list of Credit Calculation Options.
   *
   * Get Client's Credit Calculation options
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listClientCalculationOptions()` instead.
   *
   * This method doesn't expect any request body.
   */
  listClientCalculationOptions$Response(params?: {

    /**
     * How big each page should be (Default: &#x27;50&#x27;)
     */
    pageSize?: number;

    /**
     * Id to use to get the previous page. If used, afterId should not be used.
     */
    beforeId?: string;

    /**
     * Id to get the next page, If used, beforeId should not be used.
     */
    afterId?: string;

    /**
     * Collection of filters that can be used. Filter value must be Base64 encoded. Example JSON: [{&#x27;name&#x27;:&#x27;Client&#x27;,&#x27;value&#x27;:&#x27;12df9b33-ad15-4ce7-91b7-bba53a51571c&#x27;}]
     */
    filter?: string;

    /**
     * Which field to sort by
     */
    sort?: 'clientName' | 'year' | 'quarter';

    /**
     * Which order to sort by
     */
    order?: 'asc' | 'desc';
  }): Observable<StrictHttpResponse<ListClientCalculationOptionsResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CreditCalculationService.ListClientCalculationOptionsPath, 'get');
    if (params) {
      rb.query('pageSize', params.pageSize, {});
      rb.query('beforeId', params.beforeId, {});
      rb.query('afterId', params.afterId, {});
      rb.query('filter', params.filter, {});
      rb.query('sort', params.sort, {});
      rb.query('order', params.order, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ListClientCalculationOptionsResponse>;
      })
    );
  }

  /**
   * Get the list of Credit Calculation Options.
   *
   * Get Client's Credit Calculation options
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `listClientCalculationOptions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listClientCalculationOptions(params?: {

    /**
     * How big each page should be (Default: &#x27;50&#x27;)
     */
    pageSize?: number;

    /**
     * Id to use to get the previous page. If used, afterId should not be used.
     */
    beforeId?: string;

    /**
     * Id to get the next page, If used, beforeId should not be used.
     */
    afterId?: string;

    /**
     * Collection of filters that can be used. Filter value must be Base64 encoded. Example JSON: [{&#x27;name&#x27;:&#x27;Client&#x27;,&#x27;value&#x27;:&#x27;12df9b33-ad15-4ce7-91b7-bba53a51571c&#x27;}]
     */
    filter?: string;

    /**
     * Which field to sort by
     */
    sort?: 'clientName' | 'year' | 'quarter';

    /**
     * Which order to sort by
     */
    order?: 'asc' | 'desc';
  }): Observable<ListClientCalculationOptionsResponse> {

    return this.listClientCalculationOptions$Response(params).pipe(
      map((r: StrictHttpResponse<ListClientCalculationOptionsResponse>) => r.body as ListClientCalculationOptionsResponse)
    );
  }

  /**
   * Path part for operation putCreditCalculationOption
   */
  static readonly PutCreditCalculationOptionPath = '/v2/creditCalculations/options';

  /**
   * Updates Calculation Options for a client.
   *
   * Updates one or multiple calculation options.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putCreditCalculationOption()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putCreditCalculationOption$Response(params: {
    body: PutCreditCalculationOptionRequest
  }): Observable<StrictHttpResponse<PostCreditCalculationOptionResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CreditCalculationService.PutCreditCalculationOptionPath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PostCreditCalculationOptionResponse>;
      })
    );
  }

  /**
   * Updates Calculation Options for a client.
   *
   * Updates one or multiple calculation options.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putCreditCalculationOption$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putCreditCalculationOption(params: {
    body: PutCreditCalculationOptionRequest
  }): Observable<PostCreditCalculationOptionResponse> {

    return this.putCreditCalculationOption$Response(params).pipe(
      map((r: StrictHttpResponse<PostCreditCalculationOptionResponse>) => r.body as PostCreditCalculationOptionResponse)
    );
  }

  /**
   * Path part for operation postCreditCalculationOption
   */
  static readonly PostCreditCalculationOptionPath = '/v2/creditCalculations/options/{clientId}';

  /**
   * Inserts new default credit calculation options for Client.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postCreditCalculationOption()` instead.
   *
   * This method doesn't expect any request body.
   */
  postCreditCalculationOption$Response(params: {

    /**
     * ClientId for the credit calculation option records to add
     */
    clientId: string;
  }): Observable<StrictHttpResponse<PostCreditCalculationOptionResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CreditCalculationService.PostCreditCalculationOptionPath, 'post');
    if (params) {
      rb.path('clientId', params.clientId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PostCreditCalculationOptionResponse>;
      })
    );
  }

  /**
   * Inserts new default credit calculation options for Client.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postCreditCalculationOption$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  postCreditCalculationOption(params: {

    /**
     * ClientId for the credit calculation option records to add
     */
    clientId: string;
  }): Observable<PostCreditCalculationOptionResponse> {

    return this.postCreditCalculationOption$Response(params).pipe(
      map((r: StrictHttpResponse<PostCreditCalculationOptionResponse>) => r.body as PostCreditCalculationOptionResponse)
    );
  }

  /**
   * Path part for operation setCreditCalculationV2
   */
  static readonly SetCreditCalculationV2Path = '/v2/creditCalculations/{id}';

  /**
   * Update an existing credit calculation.
   *
   * Allows for updating an existing credit calculation
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setCreditCalculationV2()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setCreditCalculationV2$Response(params: {

    /**
     * Unit of Work ID for the credit calculation
     */
    id: string;
    body?: SetCreditCalculationRequest
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, CreditCalculationService.SetCreditCalculationV2Path, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Update an existing credit calculation.
   *
   * Allows for updating an existing credit calculation
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `setCreditCalculationV2$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setCreditCalculationV2(params: {

    /**
     * Unit of Work ID for the credit calculation
     */
    id: string;
    body?: SetCreditCalculationRequest
  }): Observable<void> {

    return this.setCreditCalculationV2$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getCreditCalculationsQueueSettingsV2
   */
  static readonly GetCreditCalculationsQueueSettingsV2Path = '/v2/creditCalculations/queueSettings';

  /**
   * Get Calculation Queue Settings.
   *
   * Get settings for the credit calculation queue.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCreditCalculationsQueueSettingsV2()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getCreditCalculationsQueueSettingsV2$Response(params?: {
    body?: GetCreditCalculationsQueueSettingsRequest
  }): Observable<StrictHttpResponse<GetCreditCalculationsQueueSettingsResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CreditCalculationService.GetCreditCalculationsQueueSettingsV2Path, 'get');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GetCreditCalculationsQueueSettingsResponse>;
      })
    );
  }

  /**
   * Get Calculation Queue Settings.
   *
   * Get settings for the credit calculation queue.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCreditCalculationsQueueSettingsV2$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getCreditCalculationsQueueSettingsV2(params?: {
    body?: GetCreditCalculationsQueueSettingsRequest
  }): Observable<GetCreditCalculationsQueueSettingsResponse> {

    return this.getCreditCalculationsQueueSettingsV2$Response(params).pipe(
      map((r: StrictHttpResponse<GetCreditCalculationsQueueSettingsResponse>) => r.body as GetCreditCalculationsQueueSettingsResponse)
    );
  }

  /**
   * Path part for operation setCreditCalculationsQueueSettingsV2
   */
  static readonly SetCreditCalculationsQueueSettingsV2Path = '/v2/creditCalculations/queueSettings';

  /**
   * Configure Calculation Queue Settings.
   *
   * Configures settings for the credit calculation queue.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setCreditCalculationsQueueSettingsV2()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setCreditCalculationsQueueSettingsV2$Response(params?: {
    body?: SetCreditCalculationsQueueSettingsRequest
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, CreditCalculationService.SetCreditCalculationsQueueSettingsV2Path, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Configure Calculation Queue Settings.
   *
   * Configures settings for the credit calculation queue.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `setCreditCalculationsQueueSettingsV2$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setCreditCalculationsQueueSettingsV2(params?: {
    body?: SetCreditCalculationsQueueSettingsRequest
  }): Observable<void> {

    return this.setCreditCalculationsQueueSettingsV2$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
