/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { GetClientsByClientGroupIdResponse } from '../models/get-clients-by-client-group-id-response';
import { GetCommonControlsByClientIdResponse } from '../models/get-common-controls-by-client-id-response';

@Injectable({
  providedIn: 'root',
})
export class CommoncontrolService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation commonControlCheck
   */
  static readonly CommonControlCheckPath = '/v2/commoncontrol/clients/{clientId}/check';

  /**
   * Checks if a client is a part of a common control group or not.
   *
   * Given a clientId in the uri, this endpoint returns true if the client is in a CC group. False if not.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `commonControlCheck()` instead.
   *
   * This method doesn't expect any request body.
   */
  commonControlCheck$Response(params: {

    /**
     * clientId of the client you want to check .
     */
    clientId: string;
  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, CommoncontrolService.CommonControlCheckPath, 'get');
    if (params) {
      rb.path('clientId', params.clientId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * Checks if a client is a part of a common control group or not.
   *
   * Given a clientId in the uri, this endpoint returns true if the client is in a CC group. False if not.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `commonControlCheck$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  commonControlCheck(params: {

    /**
     * clientId of the client you want to check .
     */
    clientId: string;
  }): Observable<boolean> {

    return this.commonControlCheck$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation getClientsByClientGroupId
   */
  static readonly GetClientsByClientGroupIdPath = '/v2/commoncontrol/groups/{groupId}';

  /**
   * Gets all clients in a given group.
   *
   * Returns list of all clients that belong to the given group.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getClientsByClientGroupId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getClientsByClientGroupId$Response(params: {

    /**
     * The Id of the group you want the list of clients for.
     */
    clientId: string;
  }): Observable<StrictHttpResponse<GetClientsByClientGroupIdResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CommoncontrolService.GetClientsByClientGroupIdPath, 'get');
    if (params) {
      rb.path('clientId', params.clientId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GetClientsByClientGroupIdResponse>;
      })
    );
  }

  /**
   * Gets all clients in a given group.
   *
   * Returns list of all clients that belong to the given group.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getClientsByClientGroupId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getClientsByClientGroupId(params: {

    /**
     * The Id of the group you want the list of clients for.
     */
    clientId: string;
  }): Observable<GetClientsByClientGroupIdResponse> {

    return this.getClientsByClientGroupId$Response(params).pipe(
      map((r: StrictHttpResponse<GetClientsByClientGroupIdResponse>) => r.body as GetClientsByClientGroupIdResponse)
    );
  }

  /**
   * Path part for operation getCommonControlsByClientId
   */
  static readonly GetCommonControlsByClientIdPath = '/v2/commoncontrol/{clientId}';

  /**
   * Gets Clients CC Gruops.
   *
   * Returns list of CC ID's and names that the given client is a part of.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCommonControlsByClientId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCommonControlsByClientId$Response(params: {

    /**
     * The Id of the client you want the list of CC&#x27;s for.
     */
    clientId: string;
  }): Observable<StrictHttpResponse<GetCommonControlsByClientIdResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CommoncontrolService.GetCommonControlsByClientIdPath, 'get');
    if (params) {
      rb.path('clientId', params.clientId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GetCommonControlsByClientIdResponse>;
      })
    );
  }

  /**
   * Gets Clients CC Gruops.
   *
   * Returns list of CC ID's and names that the given client is a part of.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCommonControlsByClientId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCommonControlsByClientId(params: {

    /**
     * The Id of the client you want the list of CC&#x27;s for.
     */
    clientId: string;
  }): Observable<GetCommonControlsByClientIdResponse> {

    return this.getCommonControlsByClientId$Response(params).pipe(
      map((r: StrictHttpResponse<GetCommonControlsByClientIdResponse>) => r.body as GetCommonControlsByClientIdResponse)
    );
  }

}
