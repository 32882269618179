/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ContactResponse } from '../models/contact-response';
import { CreateContactRequest } from '../models/create-contact-request';
import { ListContactTypesResponse } from '../models/list-contact-types-response';
import { ListContactsResponse } from '../models/list-contacts-response';
import { UpdateContactRequest } from '../models/update-contact-request';

@Injectable({
  providedIn: 'root',
})
export class ContactsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation createContact
   */
  static readonly CreateContactPath = '/v2/contacts';

  /**
   * Creates a new contact.
   *
   * Creates a new contact.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createContact()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createContact$Response(params: {
    body: CreateContactRequest
  }): Observable<StrictHttpResponse<ContactResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ContactsService.CreateContactPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ContactResponse>;
      })
    );
  }

  /**
   * Creates a new contact.
   *
   * Creates a new contact.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createContact$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createContact(params: {
    body: CreateContactRequest
  }): Observable<ContactResponse> {

    return this.createContact$Response(params).pipe(
      map((r: StrictHttpResponse<ContactResponse>) => r.body as ContactResponse)
    );
  }

  /**
   * Path part for operation getContact
   */
  static readonly GetContactPath = '/v2/contacts/{contactId}';

  /**
   * Get a specific contact by contactId.
   *
   * Returns more details about a specific contact.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getContact()` instead.
   *
   * This method doesn't expect any request body.
   */
  getContact$Response(params: {

    /**
     * ContactId of the contact to retrieve.
     */
    contactId: string;
  }): Observable<StrictHttpResponse<ContactResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ContactsService.GetContactPath, 'get');
    if (params) {
      rb.path('contactId', params.contactId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ContactResponse>;
      })
    );
  }

  /**
   * Get a specific contact by contactId.
   *
   * Returns more details about a specific contact.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getContact$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getContact(params: {

    /**
     * ContactId of the contact to retrieve.
     */
    contactId: string;
  }): Observable<ContactResponse> {

    return this.getContact$Response(params).pipe(
      map((r: StrictHttpResponse<ContactResponse>) => r.body as ContactResponse)
    );
  }

  /**
   * Path part for operation updateContact
   */
  static readonly UpdateContactPath = '/v2/contacts/{contactId}';

  /**
   * Update an existing contact by contactId.
   *
   * Update an existing contact.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateContact()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateContact$Response(params: {

    /**
     * ContactId of the contact to update.
     */
    contactId: string;
    body: UpdateContactRequest
  }): Observable<StrictHttpResponse<ContactResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ContactsService.UpdateContactPath, 'put');
    if (params) {
      rb.path('contactId', params.contactId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ContactResponse>;
      })
    );
  }

  /**
   * Update an existing contact by contactId.
   *
   * Update an existing contact.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateContact$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateContact(params: {

    /**
     * ContactId of the contact to update.
     */
    contactId: string;
    body: UpdateContactRequest
  }): Observable<ContactResponse> {

    return this.updateContact$Response(params).pipe(
      map((r: StrictHttpResponse<ContactResponse>) => r.body as ContactResponse)
    );
  }

  /**
   * Path part for operation deleteContact
   */
  static readonly DeleteContactPath = '/v2/contacts/{contactId}';

  /**
   * Delete a specific contact by contactId.
   *
   * Delete a specific contact.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteContact()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteContact$Response(params: {

    /**
     * ContactId of the contact to delete.
     */
    contactId: string;
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, ContactsService.DeleteContactPath, 'delete');
    if (params) {
      rb.path('contactId', params.contactId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * Delete a specific contact by contactId.
   *
   * Delete a specific contact.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteContact$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteContact(params: {

    /**
     * ContactId of the contact to delete.
     */
    contactId: string;
  }): Observable<string> {

    return this.deleteContact$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation deleteContactType
   */
  static readonly DeleteContactTypePath = '/v2/contacttypes/{contactTypeId}';

  /**
   * Delete a specific contact type by contactTypeId.
   *
   * Delete a specific contact type.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteContactType()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteContactType$Response(params: {

    /**
     * ContactTypeId of the contact type to delete.
     */
    contactTypeId: string;
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, ContactsService.DeleteContactTypePath, 'delete');
    if (params) {
      rb.path('contactTypeId', params.contactTypeId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * Delete a specific contact type by contactTypeId.
   *
   * Delete a specific contact type.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteContactType$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteContactType(params: {

    /**
     * ContactTypeId of the contact type to delete.
     */
    contactTypeId: string;
  }): Observable<string> {

    return this.deleteContactType$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation listClientContacts
   */
  static readonly ListClientContactsPath = '/v2/client/{clientId}/contacts';

  /**
   * Gets a list of contacts for a client.
   *
   * Returns a list of contacts based on sorting, paging, and filtering rules.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listClientContacts()` instead.
   *
   * This method doesn't expect any request body.
   */
  listClientContacts$Response(params: {

    /**
     * The ClientId of the client to get the contacts for.
     */
    clientId: string;

    /**
     * Which field to sort by
     */
    sort?: 'ordinal';

    /**
     * Order ascending or descending
     */
    order?: 'asc' | 'desc';

    /**
     * How big each page should be (Default: &#x27;20&#x27;)
     */
    pageSize?: number;

    /**
     * Id to use to get the previous page
     */
    beforeId?: string;

    /**
     * Id to get the next page
     */
    afterId?: string;
  }): Observable<StrictHttpResponse<ListContactsResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ContactsService.ListClientContactsPath, 'get');
    if (params) {
      rb.path('clientId', params.clientId, {});
      rb.query('sort', params.sort, {});
      rb.query('order', params.order, {});
      rb.query('pageSize', params.pageSize, {});
      rb.query('beforeId', params.beforeId, {});
      rb.query('afterId', params.afterId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ListContactsResponse>;
      })
    );
  }

  /**
   * Gets a list of contacts for a client.
   *
   * Returns a list of contacts based on sorting, paging, and filtering rules.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `listClientContacts$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listClientContacts(params: {

    /**
     * The ClientId of the client to get the contacts for.
     */
    clientId: string;

    /**
     * Which field to sort by
     */
    sort?: 'ordinal';

    /**
     * Order ascending or descending
     */
    order?: 'asc' | 'desc';

    /**
     * How big each page should be (Default: &#x27;20&#x27;)
     */
    pageSize?: number;

    /**
     * Id to use to get the previous page
     */
    beforeId?: string;

    /**
     * Id to get the next page
     */
    afterId?: string;
  }): Observable<ListContactsResponse> {

    return this.listClientContacts$Response(params).pipe(
      map((r: StrictHttpResponse<ListContactsResponse>) => r.body as ListContactsResponse)
    );
  }

  /**
   * Path part for operation listCommonControlContacts
   */
  static readonly ListCommonControlContactsPath = '/v2/commoncontrol/{commonControlId}/contacts';

  /**
   * Gets a list of contacts for a common contol.
   *
   * Returns a list of contacts based on sorting, paging, and filtering rules.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listCommonControlContacts()` instead.
   *
   * This method doesn't expect any request body.
   */
  listCommonControlContacts$Response(params: {

    /**
     * The ClientId of the client to get the contacts for.
     */
    commonControlId: string;

    /**
     * Which field to sort by
     */
    sort?: 'ordinal';

    /**
     * Order ascending or descending
     */
    order?: 'asc' | 'desc';

    /**
     * How big each page should be (Default: &#x27;20&#x27;)
     */
    pageSize?: number;

    /**
     * Id to use to get the previous page
     */
    beforeId?: string;

    /**
     * Id to get the next page
     */
    afterId?: string;
  }): Observable<StrictHttpResponse<ListContactsResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ContactsService.ListCommonControlContactsPath, 'get');
    if (params) {
      rb.path('commonControlId', params.commonControlId, {});
      rb.query('sort', params.sort, {});
      rb.query('order', params.order, {});
      rb.query('pageSize', params.pageSize, {});
      rb.query('beforeId', params.beforeId, {});
      rb.query('afterId', params.afterId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ListContactsResponse>;
      })
    );
  }

  /**
   * Gets a list of contacts for a common contol.
   *
   * Returns a list of contacts based on sorting, paging, and filtering rules.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `listCommonControlContacts$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listCommonControlContacts(params: {

    /**
     * The ClientId of the client to get the contacts for.
     */
    commonControlId: string;

    /**
     * Which field to sort by
     */
    sort?: 'ordinal';

    /**
     * Order ascending or descending
     */
    order?: 'asc' | 'desc';

    /**
     * How big each page should be (Default: &#x27;20&#x27;)
     */
    pageSize?: number;

    /**
     * Id to use to get the previous page
     */
    beforeId?: string;

    /**
     * Id to get the next page
     */
    afterId?: string;
  }): Observable<ListContactsResponse> {

    return this.listCommonControlContacts$Response(params).pipe(
      map((r: StrictHttpResponse<ListContactsResponse>) => r.body as ListContactsResponse)
    );
  }

  /**
   * Path part for operation listContactTypes
   */
  static readonly ListContactTypesPath = '/v2/contacttypes';

  /**
   * Gets a list of distinct contact types.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listContactTypes()` instead.
   *
   * This method doesn't expect any request body.
   */
  listContactTypes$Response(params?: {
  }): Observable<StrictHttpResponse<ListContactTypesResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ContactsService.ListContactTypesPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ListContactTypesResponse>;
      })
    );
  }

  /**
   * Gets a list of distinct contact types.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `listContactTypes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listContactTypes(params?: {
  }): Observable<ListContactTypesResponse> {

    return this.listContactTypes$Response(params).pipe(
      map((r: StrictHttpResponse<ListContactTypesResponse>) => r.body as ListContactTypesResponse)
    );
  }

}
