/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { GetClientRefundResponse } from '../models/get-client-refund-response';
import { ImportClientRefundDataResponse } from '../models/import-client-refund-data-response';
import { UpsertClientRefundQuarterRequest } from '../models/upsert-client-refund-quarter-request';
import { UpsertClientRefundQuarterResponse } from '../models/upsert-client-refund-quarter-response';
import { UpsertClientRefundRequest } from '../models/upsert-client-refund-request';
import { UpsertClientRefundResponse } from '../models/upsert-client-refund-response';

@Injectable({
  providedIn: 'root',
})
export class ClientrefundService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation deleteClientRefund
   */
  static readonly DeleteClientRefundPath = '/v2/clientrefund/quarter/{clientRefundQuarterId}';

  /**
   * Delete a specific Client Refund Quarter by ClientRefundQuarterId.
   *
   * Delete a specific Client Refund Quarter record.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteClientRefund()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteClientRefund$Response(params: {

    /**
     * ClientRefundQuarterId of the Client Refund Quarter record to delete.
     */
    clientRefundQuarterId: string;
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, ClientrefundService.DeleteClientRefundPath, 'delete');
    if (params) {
      rb.path('clientRefundQuarterId', params.clientRefundQuarterId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * Delete a specific Client Refund Quarter by ClientRefundQuarterId.
   *
   * Delete a specific Client Refund Quarter record.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteClientRefund$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteClientRefund(params: {

    /**
     * ClientRefundQuarterId of the Client Refund Quarter record to delete.
     */
    clientRefundQuarterId: string;
  }): Observable<string> {

    return this.deleteClientRefund$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation getClientRefund
   */
  static readonly GetClientRefundPath = '/v2/clientrefund/{clientId}';

  /**
   * Gets client refund information.
   *
   * Returns client refund information.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getClientRefund()` instead.
   *
   * This method doesn't expect any request body.
   */
  getClientRefund$Response(params: {

    /**
     * ClientId of the client refund to return info for.
     */
    clientId: string;
  }): Observable<StrictHttpResponse<GetClientRefundResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ClientrefundService.GetClientRefundPath, 'get');
    if (params) {
      rb.path('clientId', params.clientId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GetClientRefundResponse>;
      })
    );
  }

  /**
   * Gets client refund information.
   *
   * Returns client refund information.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getClientRefund$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getClientRefund(params: {

    /**
     * ClientId of the client refund to return info for.
     */
    clientId: string;
  }): Observable<GetClientRefundResponse> {

    return this.getClientRefund$Response(params).pipe(
      map((r: StrictHttpResponse<GetClientRefundResponse>) => r.body as GetClientRefundResponse)
    );
  }

  /**
   * Path part for operation importClientRefundData
   */
  static readonly ImportClientRefundDataPath = '/v2/clientrefund/import';

  /**
   * Imports Client Refund data from Import table to ClientRefund and ClientRefundQuarter tables.
   *
   * Returns list of logs, problems encountered, and records entered.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `importClientRefundData()` instead.
   *
   * This method sends `application/vnd.openxmlformats-officedocument.spreadsheetml.sheet` and handles request body of type `application/vnd.openxmlformats-officedocument.spreadsheetml.sheet`.
   */
  importClientRefundData$Response(params: {
    body: string
  }): Observable<StrictHttpResponse<ImportClientRefundDataResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ClientrefundService.ImportClientRefundDataPath, 'post');
    if (params) {
      rb.body(params.body, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ImportClientRefundDataResponse>;
      })
    );
  }

  /**
   * Imports Client Refund data from Import table to ClientRefund and ClientRefundQuarter tables.
   *
   * Returns list of logs, problems encountered, and records entered.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `importClientRefundData$Response()` instead.
   *
   * This method sends `application/vnd.openxmlformats-officedocument.spreadsheetml.sheet` and handles request body of type `application/vnd.openxmlformats-officedocument.spreadsheetml.sheet`.
   */
  importClientRefundData(params: {
    body: string
  }): Observable<ImportClientRefundDataResponse> {

    return this.importClientRefundData$Response(params).pipe(
      map((r: StrictHttpResponse<ImportClientRefundDataResponse>) => r.body as ImportClientRefundDataResponse)
    );
  }

  /**
   * Path part for operation listClientRefundCpAsV2
   */
  static readonly ListClientRefundCpAsV2Path = '/v2/clientrefund/list/cpas';

  /**
   * Get a list of distinct ClientRefund CPAs.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listClientRefundCpAsV2()` instead.
   *
   * This method doesn't expect any request body.
   */
  listClientRefundCpAsV2$Response(params?: {
  }): Observable<StrictHttpResponse<Array<string>>> {

    const rb = new RequestBuilder(this.rootUrl, ClientrefundService.ListClientRefundCpAsV2Path, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<string>>;
      })
    );
  }

  /**
   * Get a list of distinct ClientRefund CPAs.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `listClientRefundCpAsV2$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listClientRefundCpAsV2(params?: {
  }): Observable<Array<string>> {

    return this.listClientRefundCpAsV2$Response(params).pipe(
      map((r: StrictHttpResponse<Array<string>>) => r.body as Array<string>)
    );
  }

  /**
   * Path part for operation listFeinsV2
   */
  static readonly ListFeinsV2Path = '/v2/clientrefund/list/feins/{clientId}';

  /**
   * Get a list of distinct FEINs for a Client.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listFeinsV2()` instead.
   *
   * This method doesn't expect any request body.
   */
  listFeinsV2$Response(params: {

    /**
     * ClientId of the client to return FEINs for.
     */
    clientId: string;
  }): Observable<StrictHttpResponse<Array<string>>> {

    const rb = new RequestBuilder(this.rootUrl, ClientrefundService.ListFeinsV2Path, 'get');
    if (params) {
      rb.path('clientId', params.clientId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<string>>;
      })
    );
  }

  /**
   * Get a list of distinct FEINs for a Client.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `listFeinsV2$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listFeinsV2(params: {

    /**
     * ClientId of the client to return FEINs for.
     */
    clientId: string;
  }): Observable<Array<string>> {

    return this.listFeinsV2$Response(params).pipe(
      map((r: StrictHttpResponse<Array<string>>) => r.body as Array<string>)
    );
  }

  /**
   * Path part for operation upsertClientRefund
   */
  static readonly UpsertClientRefundPath = '/v2/clientrefund';

  /**
   * Updates existing or inserts a new client refund.
   *
   * Returns the clientrefund data if upsert was successful.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `upsertClientRefund()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  upsertClientRefund$Response(params: {
    body: UpsertClientRefundRequest
  }): Observable<StrictHttpResponse<UpsertClientRefundResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ClientrefundService.UpsertClientRefundPath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UpsertClientRefundResponse>;
      })
    );
  }

  /**
   * Updates existing or inserts a new client refund.
   *
   * Returns the clientrefund data if upsert was successful.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `upsertClientRefund$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  upsertClientRefund(params: {
    body: UpsertClientRefundRequest
  }): Observable<UpsertClientRefundResponse> {

    return this.upsertClientRefund$Response(params).pipe(
      map((r: StrictHttpResponse<UpsertClientRefundResponse>) => r.body as UpsertClientRefundResponse)
    );
  }

  /**
   * Path part for operation upsertClientRefundQuarter
   */
  static readonly UpsertClientRefundQuarterPath = '/v2/clientrefund/quarter';

  /**
   * Updates existing or inserts a new client refund quarter.
   *
   * Returns the clientRefundQuarter data if upsert was successful.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `upsertClientRefundQuarter()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  upsertClientRefundQuarter$Response(params: {
    body: UpsertClientRefundQuarterRequest
  }): Observable<StrictHttpResponse<UpsertClientRefundQuarterResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ClientrefundService.UpsertClientRefundQuarterPath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UpsertClientRefundQuarterResponse>;
      })
    );
  }

  /**
   * Updates existing or inserts a new client refund quarter.
   *
   * Returns the clientRefundQuarter data if upsert was successful.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `upsertClientRefundQuarter$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  upsertClientRefundQuarter(params: {
    body: UpsertClientRefundQuarterRequest
  }): Observable<UpsertClientRefundQuarterResponse> {

    return this.upsertClientRefundQuarter$Response(params).pipe(
      map((r: StrictHttpResponse<UpsertClientRefundQuarterResponse>) => r.body as UpsertClientRefundQuarterResponse)
    );
  }

}
