/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CreateReportRequest } from '../models/create-report-request';
import { CreateReportResponse } from '../models/create-report-response';

@Injectable({
  providedIn: 'root',
})
export class ReportsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation createReportV2
   */
  static readonly CreateReportV2Path = '/v2/reports';

  /**
   * Queues the requested report for creation.
   *
   * Queues the requested report for creation
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createReportV2()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createReportV2$Response(params?: {
    body?: CreateReportRequest
  }): Observable<StrictHttpResponse<CreateReportResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ReportsService.CreateReportV2Path, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CreateReportResponse>;
      })
    );
  }

  /**
   * Queues the requested report for creation.
   *
   * Queues the requested report for creation
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createReportV2$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createReportV2(params?: {
    body?: CreateReportRequest
  }): Observable<CreateReportResponse> {

    return this.createReportV2$Response(params).pipe(
      map((r: StrictHttpResponse<CreateReportResponse>) => r.body as CreateReportResponse)
    );
  }

  /**
   * Path part for operation downloadReportV2
   */
  static readonly DownloadReportV2Path = '/v2/reports/{reportId}/download';

  /**
   * Downloads the requested report data.
   *
   * Downloads the requested report data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `downloadReportV2()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadReportV2$Response(params: {

    /**
     * Id of the report
     */
    reportId: string;

    /**
     * Id of the client
     */
    clientId: string;
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, ReportsService.DownloadReportV2Path, 'get');
    if (params) {
      rb.path('reportId', params.reportId, {});
      rb.query('clientId', params.clientId, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/octet-stream'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * Downloads the requested report data.
   *
   * Downloads the requested report data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `downloadReportV2$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadReportV2(params: {

    /**
     * Id of the report
     */
    reportId: string;

    /**
     * Id of the client
     */
    clientId: string;
  }): Observable<string> {

    return this.downloadReportV2$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation renderReport
   */
  static readonly RenderReportPath = '/v2/reports/render';

  /**
   * Renders the Report right now.
   *
   * Renders the report right now and returns the file's content
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `renderReport()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  renderReport$Response(params?: {
    body?: CreateReportRequest
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, ReportsService.RenderReportPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/octet-stream'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * Renders the Report right now.
   *
   * Renders the report right now and returns the file's content
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `renderReport$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  renderReport(params?: {
    body?: CreateReportRequest
  }): Observable<string> {

    return this.renderReport$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

}
