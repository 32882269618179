import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthModule } from '@auth0/auth0-angular';
import { TranslateModule } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { ApiModule } from './api-client/api.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { ApplicationInsightsErrorHandler } from './services/applicationInsightsErrorHandler.service';
import { RouterExtService } from './services/routerExt.service';

@NgModule({
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot(),
    HttpClientModule,
    AuthModule.forRoot({
      domain: environment.auth.domain,
      clientId: environment.auth.clientID,
      audience: environment.auth.audience,
      cacheLocation: 'localstorage',
      useRefreshTokens: true,
    }),
    ApiModule.forRoot({ rootUrl: environment.api.base }),
  ],
  declarations: [AppComponent],
  providers: [
    { provide: ErrorHandler, useClass: ApplicationInsightsErrorHandler },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  // Pull this in as soon as possible to start grabbing route changes
  constructor(private routerExtService: RouterExtService) {}
}
