import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

const scripts = document.getElementsByTagName('script');
const head = document.getElementsByTagName('head')[0];
const index = scripts.length - 1;
const mainScript = scripts[index];
const deployUrl = mainScript.src.replace(/main.*?\.js$/, '');

function addLink(rel: string, type: string, relPath: string): void {
  const linkEl: HTMLLinkElement = document.createElement('link');
  linkEl.setAttribute('rel', rel);
  linkEl.setAttribute('type', type);
  linkEl.setAttribute('href', deployUrl + relPath);
  head.appendChild(linkEl);
}

addLink('icon', 'image/x-icon', 'favicon.ico');
addLink('stylesheet', 'text/css', 'assets/fonts/iconsmind-s/css/iconsminds.css');
addLink('stylesheet', 'text/css', 'assets/fonts/simple-line-icons/css/simple-line-icons.css');

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
