/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { ReportsService } from './services/reports.service';
import { ReasoncodesService } from './services/reasoncodes.service';
import { DataopsService } from './services/dataops.service';
import { CreditCalculationService } from './services/credit-calculation.service';
import { ContactsService } from './services/contacts.service';
import { CommoncontrolService } from './services/commoncontrol.service';
import { ClientService } from './services/client.service';
import { ClientSftpProvisioningService } from './services/client-sftp-provisioning.service';
import { ClientrefundService } from './services/clientrefund.service';
import { ClientLoanPeriodsService } from './services/client-loan-periods.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    ReportsService,
    ReasoncodesService,
    DataopsService,
    CreditCalculationService,
    ContactsService,
    CommoncontrolService,
    ClientService,
    ClientSftpProvisioningService,
    ClientrefundService,
    ClientLoanPeriodsService,
    ApiConfiguration
  ],
})
export class ApiModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: ApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
