/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ClientFileNamesResponse } from '../models/client-file-names-response';
import { ClientQuickInfoResponse } from '../models/client-quick-info-response';
import { CreatePayrollFollowUpRequest } from '../models/create-payroll-follow-up-request';
import { ImportCreditAuditRecordMultipartReqeust } from '../models/import-credit-audit-record-multipart-reqeust';
import { ImportCreditAuditRecordsResponse } from '../models/import-credit-audit-records-response';
import { ImportPayrollFollowUpRecordMultipartRequest } from '../models/import-payroll-follow-up-record-multipart-request';
import { ImportPayrollFollowUpResponse } from '../models/import-payroll-follow-up-response';
import { ListClientStatusesResponse } from '../models/list-client-statuses-response';
import { ListClientsMetadataResponse } from '../models/list-clients-metadata-response';
import { ListCreditAuditMetadataResponse } from '../models/list-credit-audit-metadata-response';
import { ListCreditAuditQueueStatusResponse } from '../models/list-credit-audit-queue-status-response';
import { ListFileMetadataStatusResponse } from '../models/list-file-metadata-status-response';
import { ListFilesMetadataResponse } from '../models/list-files-metadata-response';
import { ListPayrollFollowUpResponse } from '../models/list-payroll-follow-up-response';
import { ListPayrollFollowUpStatusResponse } from '../models/list-payroll-follow-up-status-response';
import { MassAddFileMetadataRequest } from '../models/mass-add-file-metadata-request';
import { MassAddFileMetadataResponse } from '../models/mass-add-file-metadata-response';
import { PostFileMetadataRequest } from '../models/post-file-metadata-request';
import { PostFileMetadataResponse } from '../models/post-file-metadata-response';
import { UpdateClientMetadataNoteRequest } from '../models/update-client-metadata-note-request';
import { UpdateClientMetadataRequest } from '../models/update-client-metadata-request';
import { UpdateClientMetadataResponse } from '../models/update-client-metadata-response';
import { UpdateClientMetadataReviewedByRequest } from '../models/update-client-metadata-reviewed-by-request';
import { UpdateClientMetadataReviewedByResponse } from '../models/update-client-metadata-reviewed-by-response';
import { UpdateCreditAuditQueueAssignedToRequest } from '../models/update-credit-audit-queue-assigned-to-request';
import { UpdateCreditAuditQueueAssignedToResponse } from '../models/update-credit-audit-queue-assigned-to-response';
import { UpdateCreditAuditQueueNoteRequest } from '../models/update-credit-audit-queue-note-request';
import { UpdateCreditAuditQueueRequest } from '../models/update-credit-audit-queue-request';
import { UpdateCreditAuditQueueResponse } from '../models/update-credit-audit-queue-response';
import { UpdateFileMetadataClientRequest } from '../models/update-file-metadata-client-request';
import { UpdateFileMetadataClientResponse } from '../models/update-file-metadata-client-response';
import { UpdateFileMetadataRequest } from '../models/update-file-metadata-request';
import { UpdateFileMetadataResponse } from '../models/update-file-metadata-response';
import { UpdatePayrollFollowUpAssignedToRequest } from '../models/update-payroll-follow-up-assigned-to-request';
import { UpdatePayrollFollowUpNoteRequest } from '../models/update-payroll-follow-up-note-request';
import { UpsertCreditAuditRecordRequest } from '../models/upsert-credit-audit-record-request';

@Injectable({
  providedIn: 'root',
})
export class DataopsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation deleteFileRecord
   */
  static readonly DeleteFileRecordPath = '/v2/dataops/files/{clientFileIngressId}';

  /**
   * Tool for developers to delete records from the ClientFileIngress table.
   *
   * Deletes all records in the ClientFileIngress and Audit table for the given Id.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteFileRecord()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteFileRecord$Response(params: {

    /**
     * clientFileIngressId of the record to be deleted.
     */
    clientFileIngressId: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, DataopsService.DeleteFileRecordPath, 'delete');
    if (params) {
      rb.path('clientFileIngressId', params.clientFileIngressId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Tool for developers to delete records from the ClientFileIngress table.
   *
   * Deletes all records in the ClientFileIngress and Audit table for the given Id.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteFileRecord$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteFileRecord(params: {

    /**
     * clientFileIngressId of the record to be deleted.
     */
    clientFileIngressId: string;
  }): Observable<void> {

    return this.deleteFileRecord$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getClientQuickInfo
   */
  static readonly GetClientQuickInfoPath = '/v2/dataops/clients/{clientId}/info';

  /**
   * Gets quick information for a specific client.
   *
   * Returns some for a client to be displayed on the dataops page
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getClientQuickInfo()` instead.
   *
   * This method doesn't expect any request body.
   */
  getClientQuickInfo$Response(params: {

    /**
     * ClientId of the client to return info for.
     */
    clientId: string;
  }): Observable<StrictHttpResponse<ClientQuickInfoResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DataopsService.GetClientQuickInfoPath, 'get');
    if (params) {
      rb.path('clientId', params.clientId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClientQuickInfoResponse>;
      })
    );
  }

  /**
   * Gets quick information for a specific client.
   *
   * Returns some for a client to be displayed on the dataops page
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getClientQuickInfo$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getClientQuickInfo(params: {

    /**
     * ClientId of the client to return info for.
     */
    clientId: string;
  }): Observable<ClientQuickInfoResponse> {

    return this.getClientQuickInfo$Response(params).pipe(
      map((r: StrictHttpResponse<ClientQuickInfoResponse>) => r.body as ClientQuickInfoResponse)
    );
  }

  /**
   * Path part for operation importCreditAuditRecords
   */
  static readonly ImportCreditAuditRecordsPath = '/v2/dataops/creditaudits/import';

  /**
   * Import credit audit records from Excel or CSV.
   *
   * Import credit audit records from Excel or CSV.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `importCreditAuditRecords$Csv()` instead.
   *
   * This method sends `text/csv` and handles request body of type `text/csv`.
   */
  importCreditAuditRecords$Csv$Response(params: {
    body: string
  }): Observable<StrictHttpResponse<ImportCreditAuditRecordsResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DataopsService.ImportCreditAuditRecordsPath, 'put');
    if (params) {
      rb.body(params.body, 'text/csv');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ImportCreditAuditRecordsResponse>;
      })
    );
  }

  /**
   * Import credit audit records from Excel or CSV.
   *
   * Import credit audit records from Excel or CSV.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `importCreditAuditRecords$Csv$Response()` instead.
   *
   * This method sends `text/csv` and handles request body of type `text/csv`.
   */
  importCreditAuditRecords$Csv(params: {
    body: string
  }): Observable<ImportCreditAuditRecordsResponse> {

    return this.importCreditAuditRecords$Csv$Response(params).pipe(
      map((r: StrictHttpResponse<ImportCreditAuditRecordsResponse>) => r.body as ImportCreditAuditRecordsResponse)
    );
  }

  /**
   * Import credit audit records from Excel or CSV.
   *
   * Import credit audit records from Excel or CSV.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `importCreditAuditRecords$VndOpenxmlformatsOfficedocumentSpreadsheetmlSheet()` instead.
   *
   * This method sends `application/vnd.openxmlformats-officedocument.spreadsheetml.sheet` and handles request body of type `application/vnd.openxmlformats-officedocument.spreadsheetml.sheet`.
   */
  importCreditAuditRecords$VndOpenxmlformatsOfficedocumentSpreadsheetmlSheet$Response(params: {
    body: string
  }): Observable<StrictHttpResponse<ImportCreditAuditRecordsResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DataopsService.ImportCreditAuditRecordsPath, 'put');
    if (params) {
      rb.body(params.body, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ImportCreditAuditRecordsResponse>;
      })
    );
  }

  /**
   * Import credit audit records from Excel or CSV.
   *
   * Import credit audit records from Excel or CSV.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `importCreditAuditRecords$VndOpenxmlformatsOfficedocumentSpreadsheetmlSheet$Response()` instead.
   *
   * This method sends `application/vnd.openxmlformats-officedocument.spreadsheetml.sheet` and handles request body of type `application/vnd.openxmlformats-officedocument.spreadsheetml.sheet`.
   */
  importCreditAuditRecords$VndOpenxmlformatsOfficedocumentSpreadsheetmlSheet(params: {
    body: string
  }): Observable<ImportCreditAuditRecordsResponse> {

    return this.importCreditAuditRecords$VndOpenxmlformatsOfficedocumentSpreadsheetmlSheet$Response(params).pipe(
      map((r: StrictHttpResponse<ImportCreditAuditRecordsResponse>) => r.body as ImportCreditAuditRecordsResponse)
    );
  }

  /**
   * Import credit audit records from Excel or CSV.
   *
   * Import credit audit records from Excel or CSV.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `importCreditAuditRecords$FormData()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  importCreditAuditRecords$FormData$Response(params: {
    body: ImportCreditAuditRecordMultipartReqeust
  }): Observable<StrictHttpResponse<ImportCreditAuditRecordsResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DataopsService.ImportCreditAuditRecordsPath, 'put');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ImportCreditAuditRecordsResponse>;
      })
    );
  }

  /**
   * Import credit audit records from Excel or CSV.
   *
   * Import credit audit records from Excel or CSV.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `importCreditAuditRecords$FormData$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  importCreditAuditRecords$FormData(params: {
    body: ImportCreditAuditRecordMultipartReqeust
  }): Observable<ImportCreditAuditRecordsResponse> {

    return this.importCreditAuditRecords$FormData$Response(params).pipe(
      map((r: StrictHttpResponse<ImportCreditAuditRecordsResponse>) => r.body as ImportCreditAuditRecordsResponse)
    );
  }

  /**
   * Path part for operation listClientFileNames
   */
  static readonly ListClientFileNamesPath = '/v2/dataops/clients/{clientId}/filenames';

  /**
   * Gets a list of file names that belong to a client.
   *
   * Returns a list of clients file names.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listClientFileNames()` instead.
   *
   * This method doesn't expect any request body.
   */
  listClientFileNames$Response(params: {

    /**
     * ClientId of the client to return file names for.
     */
    clientId: string;
  }): Observable<StrictHttpResponse<ClientFileNamesResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DataopsService.ListClientFileNamesPath, 'get');
    if (params) {
      rb.path('clientId', params.clientId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClientFileNamesResponse>;
      })
    );
  }

  /**
   * Gets a list of file names that belong to a client.
   *
   * Returns a list of clients file names.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `listClientFileNames$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listClientFileNames(params: {

    /**
     * ClientId of the client to return file names for.
     */
    clientId: string;
  }): Observable<ClientFileNamesResponse> {

    return this.listClientFileNames$Response(params).pipe(
      map((r: StrictHttpResponse<ClientFileNamesResponse>) => r.body as ClientFileNamesResponse)
    );
  }

  /**
   * Path part for operation listClientsMetadata
   */
  static readonly ListClientsMetadataPath = '/v2/dataops/clients';

  /**
   * Gets a list of clients metadata.
   *
   * Returns a list of clients metadata based on sorting, paging, and filtering rules.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listClientsMetadata()` instead.
   *
   * This method doesn't expect any request body.
   */
  listClientsMetadata$Response(params?: {

    /**
     * How big each page should be (Default: &#x27;50&#x27;)
     */
    pageSize?: number;

    /**
     * Id to use to get the previous page. If used, afterId should not be used.
     */
    beforeId?: string;

    /**
     * Id to get the next page, If used, beforeId should not be used.
     */
    afterId?: string;

    /**
     * Collection of filters that can be used. Filter value must be Base64 encoded. Example JSON: [{&#x27;name&#x27;:&#x27;Folder&#x27;,&#x27;value&#x27;:&#x27;Test Folder&#x27;},{&#x27;name&#x27;:&#x27;Client&#x27;,&#x27;value&#x27;:&#x27;b5838e48-f6b3-9104-6af9-4e96a1fb8d63&#x27;},{&#x27;name&#x27;:&#x27;Status&#x27;,&#x27;value&#x27;:&#x27;1&#x27;},{&#x27;name&#x27;:&#x27;Status&#x27;,&#x27;value&#x27;:&#x27;3&#x27;}]
     */
    filter?: string;

    /**
     * Which field to sort by
     */
    sort?: 'assignedToThenOldest' | 'assignedToThenNewest' | 'dateCompleted';

    /**
     * Which order to sort by
     */
    order?: 'asc' | 'desc';
  }): Observable<StrictHttpResponse<ListClientsMetadataResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DataopsService.ListClientsMetadataPath, 'get');
    if (params) {
      rb.query('pageSize', params.pageSize, {});
      rb.query('beforeId', params.beforeId, {});
      rb.query('afterId', params.afterId, {});
      rb.query('filter', params.filter, {});
      rb.query('sort', params.sort, {});
      rb.query('order', params.order, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ListClientsMetadataResponse>;
      })
    );
  }

  /**
   * Gets a list of clients metadata.
   *
   * Returns a list of clients metadata based on sorting, paging, and filtering rules.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `listClientsMetadata$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listClientsMetadata(params?: {

    /**
     * How big each page should be (Default: &#x27;50&#x27;)
     */
    pageSize?: number;

    /**
     * Id to use to get the previous page. If used, afterId should not be used.
     */
    beforeId?: string;

    /**
     * Id to get the next page, If used, beforeId should not be used.
     */
    afterId?: string;

    /**
     * Collection of filters that can be used. Filter value must be Base64 encoded. Example JSON: [{&#x27;name&#x27;:&#x27;Folder&#x27;,&#x27;value&#x27;:&#x27;Test Folder&#x27;},{&#x27;name&#x27;:&#x27;Client&#x27;,&#x27;value&#x27;:&#x27;b5838e48-f6b3-9104-6af9-4e96a1fb8d63&#x27;},{&#x27;name&#x27;:&#x27;Status&#x27;,&#x27;value&#x27;:&#x27;1&#x27;},{&#x27;name&#x27;:&#x27;Status&#x27;,&#x27;value&#x27;:&#x27;3&#x27;}]
     */
    filter?: string;

    /**
     * Which field to sort by
     */
    sort?: 'assignedToThenOldest' | 'assignedToThenNewest' | 'dateCompleted';

    /**
     * Which order to sort by
     */
    order?: 'asc' | 'desc';
  }): Observable<ListClientsMetadataResponse> {

    return this.listClientsMetadata$Response(params).pipe(
      map((r: StrictHttpResponse<ListClientsMetadataResponse>) => r.body as ListClientsMetadataResponse)
    );
  }

  /**
   * Path part for operation listDataopsClientStatuses
   */
  static readonly ListDataopsClientStatusesPath = '/v2/dataops/clients/statuses';

  /**
   * Gets a list of distinct client statuses.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listDataopsClientStatuses()` instead.
   *
   * This method doesn't expect any request body.
   */
  listDataopsClientStatuses$Response(params?: {
  }): Observable<StrictHttpResponse<ListClientStatusesResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DataopsService.ListDataopsClientStatusesPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ListClientStatusesResponse>;
      })
    );
  }

  /**
   * Gets a list of distinct client statuses.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `listDataopsClientStatuses$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listDataopsClientStatuses(params?: {
  }): Observable<ListClientStatusesResponse> {

    return this.listDataopsClientStatuses$Response(params).pipe(
      map((r: StrictHttpResponse<ListClientStatusesResponse>) => r.body as ListClientStatusesResponse)
    );
  }

  /**
   * Path part for operation listCreditAuditMetadata
   */
  static readonly ListCreditAuditMetadataPath = '/v2/dataops/creditaudits';

  /**
   * Gets a list of credit audit metadata.
   *
   * Returns a list of credit audit metadata based on sorting, paging, and filtering rules.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listCreditAuditMetadata()` instead.
   *
   * This method doesn't expect any request body.
   */
  listCreditAuditMetadata$Response(params?: {

    /**
     * How big each page should be (Default: &#x27;50&#x27;)
     */
    pageSize?: number;

    /**
     * Id to use to get the previous page. If used, afterId should not be used.
     */
    beforeId?: string;

    /**
     * Id to get the next page, If used, beforeId should not be used.
     */
    afterId?: string;

    /**
     * Collection of filters that can be used. Filter value must be Base64 encoded. Example JSON: [{&#x27;name&#x27;:&#x27;Folder&#x27;,&#x27;value&#x27;:&#x27;Test Folder&#x27;},{&#x27;name&#x27;:&#x27;Client&#x27;,&#x27;value&#x27;:&#x27;b5838e48-f6b3-9104-6af9-4e96a1fb8d63&#x27;},{&#x27;name&#x27;:&#x27;Status&#x27;,&#x27;value&#x27;:&#x27;1&#x27;},{&#x27;name&#x27;:&#x27;Status&#x27;,&#x27;value&#x27;:&#x27;3&#x27;}]
     */
    filter?: string;

    /**
     * Which field to sort by
     */
    sort?: 'newCredit';

    /**
     * Which order to sort by
     */
    order?: 'asc' | 'desc';
  }): Observable<StrictHttpResponse<ListCreditAuditMetadataResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DataopsService.ListCreditAuditMetadataPath, 'get');
    if (params) {
      rb.query('pageSize', params.pageSize, {});
      rb.query('beforeId', params.beforeId, {});
      rb.query('afterId', params.afterId, {});
      rb.query('filter', params.filter, {});
      rb.query('sort', params.sort, {});
      rb.query('order', params.order, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ListCreditAuditMetadataResponse>;
      })
    );
  }

  /**
   * Gets a list of credit audit metadata.
   *
   * Returns a list of credit audit metadata based on sorting, paging, and filtering rules.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `listCreditAuditMetadata$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listCreditAuditMetadata(params?: {

    /**
     * How big each page should be (Default: &#x27;50&#x27;)
     */
    pageSize?: number;

    /**
     * Id to use to get the previous page. If used, afterId should not be used.
     */
    beforeId?: string;

    /**
     * Id to get the next page, If used, beforeId should not be used.
     */
    afterId?: string;

    /**
     * Collection of filters that can be used. Filter value must be Base64 encoded. Example JSON: [{&#x27;name&#x27;:&#x27;Folder&#x27;,&#x27;value&#x27;:&#x27;Test Folder&#x27;},{&#x27;name&#x27;:&#x27;Client&#x27;,&#x27;value&#x27;:&#x27;b5838e48-f6b3-9104-6af9-4e96a1fb8d63&#x27;},{&#x27;name&#x27;:&#x27;Status&#x27;,&#x27;value&#x27;:&#x27;1&#x27;},{&#x27;name&#x27;:&#x27;Status&#x27;,&#x27;value&#x27;:&#x27;3&#x27;}]
     */
    filter?: string;

    /**
     * Which field to sort by
     */
    sort?: 'newCredit';

    /**
     * Which order to sort by
     */
    order?: 'asc' | 'desc';
  }): Observable<ListCreditAuditMetadataResponse> {

    return this.listCreditAuditMetadata$Response(params).pipe(
      map((r: StrictHttpResponse<ListCreditAuditMetadataResponse>) => r.body as ListCreditAuditMetadataResponse)
    );
  }

  /**
   * Path part for operation updateCreditAuditQueue
   */
  static readonly UpdateCreditAuditQueuePath = '/v2/dataops/creditaudits';

  /**
   * Updates existing clients credit audit records.
   *
   * Updates existing credit audit queue records for AssignedTo, LastReviewedBy, Status and Notes.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateCreditAuditQueue()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateCreditAuditQueue$Response(params: {
    body: UpdateCreditAuditQueueRequest
  }): Observable<StrictHttpResponse<UpdateCreditAuditQueueResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DataopsService.UpdateCreditAuditQueuePath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UpdateCreditAuditQueueResponse>;
      })
    );
  }

  /**
   * Updates existing clients credit audit records.
   *
   * Updates existing credit audit queue records for AssignedTo, LastReviewedBy, Status and Notes.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateCreditAuditQueue$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateCreditAuditQueue(params: {
    body: UpdateCreditAuditQueueRequest
  }): Observable<UpdateCreditAuditQueueResponse> {

    return this.updateCreditAuditQueue$Response(params).pipe(
      map((r: StrictHttpResponse<UpdateCreditAuditQueueResponse>) => r.body as UpdateCreditAuditQueueResponse)
    );
  }

  /**
   * Path part for operation listCreditAuditQueueStatuses
   */
  static readonly ListCreditAuditQueueStatusesPath = '/v2/dataops/creditaudits/statuses';

  /**
   * Gets a list of distinct credit audit queue statuses.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listCreditAuditQueueStatuses()` instead.
   *
   * This method doesn't expect any request body.
   */
  listCreditAuditQueueStatuses$Response(params?: {
  }): Observable<StrictHttpResponse<ListCreditAuditQueueStatusResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DataopsService.ListCreditAuditQueueStatusesPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ListCreditAuditQueueStatusResponse>;
      })
    );
  }

  /**
   * Gets a list of distinct credit audit queue statuses.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `listCreditAuditQueueStatuses$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listCreditAuditQueueStatuses(params?: {
  }): Observable<ListCreditAuditQueueStatusResponse> {

    return this.listCreditAuditQueueStatuses$Response(params).pipe(
      map((r: StrictHttpResponse<ListCreditAuditQueueStatusResponse>) => r.body as ListCreditAuditQueueStatusResponse)
    );
  }

  /**
   * Path part for operation listFileMetadataClientFolderNames
   */
  static readonly ListFileMetadataClientFolderNamesPath = '/v2/dataops/files/folders';

  /**
   * Gets a list of distinct client folder names.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listFileMetadataClientFolderNames()` instead.
   *
   * This method doesn't expect any request body.
   */
  listFileMetadataClientFolderNames$Response(params?: {
  }): Observable<StrictHttpResponse<Array<string>>> {

    const rb = new RequestBuilder(this.rootUrl, DataopsService.ListFileMetadataClientFolderNamesPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<string>>;
      })
    );
  }

  /**
   * Gets a list of distinct client folder names.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `listFileMetadataClientFolderNames$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listFileMetadataClientFolderNames(params?: {
  }): Observable<Array<string>> {

    return this.listFileMetadataClientFolderNames$Response(params).pipe(
      map((r: StrictHttpResponse<Array<string>>) => r.body as Array<string>)
    );
  }

  /**
   * Path part for operation listFileMetadataStatuses
   */
  static readonly ListFileMetadataStatusesPath = '/v2/dataops/files/statuses';

  /**
   * Gets a list of distinct file metadata statuses.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listFileMetadataStatuses()` instead.
   *
   * This method doesn't expect any request body.
   */
  listFileMetadataStatuses$Response(params?: {
  }): Observable<StrictHttpResponse<ListFileMetadataStatusResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DataopsService.ListFileMetadataStatusesPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ListFileMetadataStatusResponse>;
      })
    );
  }

  /**
   * Gets a list of distinct file metadata statuses.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `listFileMetadataStatuses$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listFileMetadataStatuses(params?: {
  }): Observable<ListFileMetadataStatusResponse> {

    return this.listFileMetadataStatuses$Response(params).pipe(
      map((r: StrictHttpResponse<ListFileMetadataStatusResponse>) => r.body as ListFileMetadataStatusResponse)
    );
  }

  /**
   * Path part for operation listFilesMetadata
   */
  static readonly ListFilesMetadataPath = '/v2/dataops/files';

  /**
   * Gets a list of files metadata.
   *
   * Returns a list of files metadata based on sorting, paging, and filtering rules.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listFilesMetadata()` instead.
   *
   * This method doesn't expect any request body.
   */
  listFilesMetadata$Response(params?: {

    /**
     * How big each page should be (Default: &#x27;20&#x27;)
     */
    pageSize?: number;

    /**
     * Id to use to get the previous page. If used, afterId should not be used.
     */
    beforeId?: number;

    /**
     * Id to get the next page, If used, beforeId should not be used.
     */
    afterId?: number;

    /**
     * Collection of filters that can be used. Filter value must be Base64 encoded. Example JSON: [{&#x27;name&#x27;:&#x27;Folder&#x27;,&#x27;value&#x27;:&#x27;Test Folder&#x27;},{&#x27;name&#x27;:&#x27;Client&#x27;,&#x27;value&#x27;:&#x27;b5838e48-f6b3-9104-6af9-4e96a1fb8d63&#x27;},{&#x27;name&#x27;:&#x27;Status&#x27;,&#x27;value&#x27;:&#x27;1&#x27;},{&#x27;name&#x27;:&#x27;Status&#x27;,&#x27;value&#x27;:&#x27;3&#x27;}]
     */
    filter?: string;

    /**
     * Which field to sort by
     */
    sort?: 'dateInsertedUtc';

    /**
     * Which order to sort by
     */
    order?: 'asc' | 'desc';
  }): Observable<StrictHttpResponse<ListFilesMetadataResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DataopsService.ListFilesMetadataPath, 'get');
    if (params) {
      rb.query('pageSize', params.pageSize, {});
      rb.query('beforeId', params.beforeId, {});
      rb.query('afterId', params.afterId, {});
      rb.query('filter', params.filter, {});
      rb.query('sort', params.sort, {});
      rb.query('order', params.order, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ListFilesMetadataResponse>;
      })
    );
  }

  /**
   * Gets a list of files metadata.
   *
   * Returns a list of files metadata based on sorting, paging, and filtering rules.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `listFilesMetadata$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listFilesMetadata(params?: {

    /**
     * How big each page should be (Default: &#x27;20&#x27;)
     */
    pageSize?: number;

    /**
     * Id to use to get the previous page. If used, afterId should not be used.
     */
    beforeId?: number;

    /**
     * Id to get the next page, If used, beforeId should not be used.
     */
    afterId?: number;

    /**
     * Collection of filters that can be used. Filter value must be Base64 encoded. Example JSON: [{&#x27;name&#x27;:&#x27;Folder&#x27;,&#x27;value&#x27;:&#x27;Test Folder&#x27;},{&#x27;name&#x27;:&#x27;Client&#x27;,&#x27;value&#x27;:&#x27;b5838e48-f6b3-9104-6af9-4e96a1fb8d63&#x27;},{&#x27;name&#x27;:&#x27;Status&#x27;,&#x27;value&#x27;:&#x27;1&#x27;},{&#x27;name&#x27;:&#x27;Status&#x27;,&#x27;value&#x27;:&#x27;3&#x27;}]
     */
    filter?: string;

    /**
     * Which field to sort by
     */
    sort?: 'dateInsertedUtc';

    /**
     * Which order to sort by
     */
    order?: 'asc' | 'desc';
  }): Observable<ListFilesMetadataResponse> {

    return this.listFilesMetadata$Response(params).pipe(
      map((r: StrictHttpResponse<ListFilesMetadataResponse>) => r.body as ListFilesMetadataResponse)
    );
  }

  /**
   * Path part for operation updateFilesMetadata
   */
  static readonly UpdateFilesMetadataPath = '/v2/dataops/files';

  /**
   * Updates existing FileMetaData records.
   *
   * Updates existing FileMetaData records statuses, clientId and note.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateFilesMetadata()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateFilesMetadata$Response(params: {
    body: UpdateFileMetadataRequest
  }): Observable<StrictHttpResponse<UpdateFileMetadataResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DataopsService.UpdateFilesMetadataPath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UpdateFileMetadataResponse>;
      })
    );
  }

  /**
   * Updates existing FileMetaData records.
   *
   * Updates existing FileMetaData records statuses, clientId and note.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateFilesMetadata$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateFilesMetadata(params: {
    body: UpdateFileMetadataRequest
  }): Observable<UpdateFileMetadataResponse> {

    return this.updateFilesMetadata$Response(params).pipe(
      map((r: StrictHttpResponse<UpdateFileMetadataResponse>) => r.body as UpdateFileMetadataResponse)
    );
  }

  /**
   * Path part for operation postFileMetadata
   */
  static readonly PostFileMetadataPath = '/v2/dataops/files';

  /**
   * Uploads file metadata for the Data Ops Dashboard.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postFileMetadata()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postFileMetadata$Response(params: {
    body: PostFileMetadataRequest
  }): Observable<StrictHttpResponse<PostFileMetadataResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DataopsService.PostFileMetadataPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PostFileMetadataResponse>;
      })
    );
  }

  /**
   * Uploads file metadata for the Data Ops Dashboard.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postFileMetadata$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postFileMetadata(params: {
    body: PostFileMetadataRequest
  }): Observable<PostFileMetadataResponse> {

    return this.postFileMetadata$Response(params).pipe(
      map((r: StrictHttpResponse<PostFileMetadataResponse>) => r.body as PostFileMetadataResponse)
    );
  }

  /**
   * Path part for operation massAddFileMetadata
   */
  static readonly MassAddFileMetadataPath = '/v2/dataops/files/bulk';

  /**
   * Uploads file metadata for the Data Ops Dashboard.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `massAddFileMetadata()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  massAddFileMetadata$Response(params: {
    body: MassAddFileMetadataRequest
  }): Observable<StrictHttpResponse<MassAddFileMetadataResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DataopsService.MassAddFileMetadataPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MassAddFileMetadataResponse>;
      })
    );
  }

  /**
   * Uploads file metadata for the Data Ops Dashboard.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `massAddFileMetadata$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  massAddFileMetadata(params: {
    body: MassAddFileMetadataRequest
  }): Observable<MassAddFileMetadataResponse> {

    return this.massAddFileMetadata$Response(params).pipe(
      map((r: StrictHttpResponse<MassAddFileMetadataResponse>) => r.body as MassAddFileMetadataResponse)
    );
  }

  /**
   * Path part for operation unassignClient
   */
  static readonly UnassignClientPath = '/v2/dataops/clients/{clientId}/unassign';

  /**
   * Unassigns a user from a Client existing in the ClientMetaData records.
   *
   * Unassigns a user from an existing ClientMetaData record.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `unassignClient()` instead.
   *
   * This method doesn't expect any request body.
   */
  unassignClient$Response(params: {

    /**
     * ClientId of the client to perform the unassignment on.
     */
    clientId: string;
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, DataopsService.UnassignClientPath, 'put');
    if (params) {
      rb.path('clientId', params.clientId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * Unassigns a user from a Client existing in the ClientMetaData records.
   *
   * Unassigns a user from an existing ClientMetaData record.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `unassignClient$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  unassignClient(params: {

    /**
     * ClientId of the client to perform the unassignment on.
     */
    clientId: string;
  }): Observable<string> {

    return this.unassignClient$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation updateClientMetadata
   */
  static readonly UpdateClientMetadataPath = '/v2/dataops/clients/status';

  /**
   * Updates existing ClientMetaData records.
   *
   * Updates existing ClientMetaData records statuses, clientId and note.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateClientMetadata()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateClientMetadata$Response(params: {
    body: UpdateClientMetadataRequest
  }): Observable<StrictHttpResponse<UpdateClientMetadataResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DataopsService.UpdateClientMetadataPath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UpdateClientMetadataResponse>;
      })
    );
  }

  /**
   * Updates existing ClientMetaData records.
   *
   * Updates existing ClientMetaData records statuses, clientId and note.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateClientMetadata$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateClientMetadata(params: {
    body: UpdateClientMetadataRequest
  }): Observable<UpdateClientMetadataResponse> {

    return this.updateClientMetadata$Response(params).pipe(
      map((r: StrictHttpResponse<UpdateClientMetadataResponse>) => r.body as UpdateClientMetadataResponse)
    );
  }

  /**
   * Path part for operation updateClientMetadataNote
   */
  static readonly UpdateClientMetadataNotePath = '/v2/dataops/clients/{clientId}/note';

  /**
   * Updates only note for existing ClientMetadata record.
   *
   * Updates only note for existing ClientMetadata record.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateClientMetadataNote()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateClientMetadataNote$Response(params: {

    /**
     * ClientId of the client to update note for.
     */
    clientId: string;
    body: UpdateClientMetadataNoteRequest
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, DataopsService.UpdateClientMetadataNotePath, 'put');
    if (params) {
      rb.path('clientId', params.clientId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Updates only note for existing ClientMetadata record.
   *
   * Updates only note for existing ClientMetadata record.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateClientMetadataNote$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateClientMetadataNote(params: {

    /**
     * ClientId of the client to update note for.
     */
    clientId: string;
    body: UpdateClientMetadataNoteRequest
  }): Observable<void> {

    return this.updateClientMetadataNote$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation updateClientMetadataReviewedBy
   */
  static readonly UpdateClientMetadataReviewedByPath = '/v2/dataops/clients/reviewedby';

  /**
   * Updates ClientFileIngressWorking records ReviewedBy field.
   *
   * Updates ClientFileIngressWorking records ReviewedBy field.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateClientMetadataReviewedBy()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateClientMetadataReviewedBy$Response(params: {
    body: UpdateClientMetadataReviewedByRequest
  }): Observable<StrictHttpResponse<UpdateClientMetadataReviewedByResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DataopsService.UpdateClientMetadataReviewedByPath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UpdateClientMetadataReviewedByResponse>;
      })
    );
  }

  /**
   * Updates ClientFileIngressWorking records ReviewedBy field.
   *
   * Updates ClientFileIngressWorking records ReviewedBy field.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateClientMetadataReviewedBy$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateClientMetadataReviewedBy(params: {
    body: UpdateClientMetadataReviewedByRequest
  }): Observable<UpdateClientMetadataReviewedByResponse> {

    return this.updateClientMetadataReviewedBy$Response(params).pipe(
      map((r: StrictHttpResponse<UpdateClientMetadataReviewedByResponse>) => r.body as UpdateClientMetadataReviewedByResponse)
    );
  }

  /**
   * Path part for operation updateCreditAuditQueueAssignedTo
   */
  static readonly UpdateCreditAuditQueueAssignedToPath = '/v2/dataops/creditaudits/{clientId}/assignedto';

  /**
   * Updates existing credit audit record "AssignedTo" field.
   *
   * Updates existing credit audit record "AssignedTo" field.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateCreditAuditQueueAssignedTo()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateCreditAuditQueueAssignedTo$Response(params: {

    /**
     * ClientId of the credit audit to update assignedTo for.
     */
    clientId: string;
    body: UpdateCreditAuditQueueAssignedToRequest
  }): Observable<StrictHttpResponse<UpdateCreditAuditQueueAssignedToResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DataopsService.UpdateCreditAuditQueueAssignedToPath, 'put');
    if (params) {
      rb.path('clientId', params.clientId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UpdateCreditAuditQueueAssignedToResponse>;
      })
    );
  }

  /**
   * Updates existing credit audit record "AssignedTo" field.
   *
   * Updates existing credit audit record "AssignedTo" field.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateCreditAuditQueueAssignedTo$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateCreditAuditQueueAssignedTo(params: {

    /**
     * ClientId of the credit audit to update assignedTo for.
     */
    clientId: string;
    body: UpdateCreditAuditQueueAssignedToRequest
  }): Observable<UpdateCreditAuditQueueAssignedToResponse> {

    return this.updateCreditAuditQueueAssignedTo$Response(params).pipe(
      map((r: StrictHttpResponse<UpdateCreditAuditQueueAssignedToResponse>) => r.body as UpdateCreditAuditQueueAssignedToResponse)
    );
  }

  /**
   * Path part for operation updateCreditAuditQueueNote
   */
  static readonly UpdateCreditAuditQueueNotePath = '/v2/dataops/creditaudits/{clientId}/note';

  /**
   * Updates only note for existing CreditAuditQueue record.
   *
   * Updates only note for existing CreditAuditQueue record.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateCreditAuditQueueNote()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateCreditAuditQueueNote$Response(params: {

    /**
     * ClientId of the credit audit to update note for.
     */
    clientId: string;
    body: UpdateCreditAuditQueueNoteRequest
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, DataopsService.UpdateCreditAuditQueueNotePath, 'put');
    if (params) {
      rb.path('clientId', params.clientId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Updates only note for existing CreditAuditQueue record.
   *
   * Updates only note for existing CreditAuditQueue record.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateCreditAuditQueueNote$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateCreditAuditQueueNote(params: {

    /**
     * ClientId of the credit audit to update note for.
     */
    clientId: string;
    body: UpdateCreditAuditQueueNoteRequest
  }): Observable<void> {

    return this.updateCreditAuditQueueNote$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation updateFileMetadataClient
   */
  static readonly UpdateFileMetadataClientPath = '/v2/dataops/files/assignClient';

  /**
   * Updates only clientId for existing FileMetadata records.
   *
   * Updates only clientId for existing FileMetadata records.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateFileMetadataClient()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateFileMetadataClient$Response(params: {
    body: UpdateFileMetadataClientRequest
  }): Observable<StrictHttpResponse<UpdateFileMetadataClientResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DataopsService.UpdateFileMetadataClientPath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UpdateFileMetadataClientResponse>;
      })
    );
  }

  /**
   * Updates only clientId for existing FileMetadata records.
   *
   * Updates only clientId for existing FileMetadata records.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateFileMetadataClient$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateFileMetadataClient(params: {
    body: UpdateFileMetadataClientRequest
  }): Observable<UpdateFileMetadataClientResponse> {

    return this.updateFileMetadataClient$Response(params).pipe(
      map((r: StrictHttpResponse<UpdateFileMetadataClientResponse>) => r.body as UpdateFileMetadataClientResponse)
    );
  }

  /**
   * Path part for operation upsertCreditAuditRecord
   */
  static readonly UpsertCreditAuditRecordPath = '/v2/dataops/creditaudits/upsert';

  /**
   * Adds or updates Credit Audit record.
   *
   * Adds new Credit Audit record if not exist with new status or updates Credit Audit record approval statuses to "New" or updates Denied statuses to "Denied New Calc".
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `upsertCreditAuditRecord()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  upsertCreditAuditRecord$Response(params: {
    body: UpsertCreditAuditRecordRequest
  }): Observable<StrictHttpResponse<ImportCreditAuditRecordsResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DataopsService.UpsertCreditAuditRecordPath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ImportCreditAuditRecordsResponse>;
      })
    );
  }

  /**
   * Adds or updates Credit Audit record.
   *
   * Adds new Credit Audit record if not exist with new status or updates Credit Audit record approval statuses to "New" or updates Denied statuses to "Denied New Calc".
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `upsertCreditAuditRecord$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  upsertCreditAuditRecord(params: {
    body: UpsertCreditAuditRecordRequest
  }): Observable<ImportCreditAuditRecordsResponse> {

    return this.upsertCreditAuditRecord$Response(params).pipe(
      map((r: StrictHttpResponse<ImportCreditAuditRecordsResponse>) => r.body as ImportCreditAuditRecordsResponse)
    );
  }

  /**
   * Path part for operation listPayrollFollowUp
   */
  static readonly ListPayrollFollowUpPath = '/v2/dataops/payroll';

  /**
   * Gets a list of clients with payroll follow up needs.
   *
   * Returns a list of client payroll data based on sorting, paging, and filtering rules.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listPayrollFollowUp()` instead.
   *
   * This method doesn't expect any request body.
   */
  listPayrollFollowUp$Response(params?: {

    /**
     * How big each page should be (Default: &#x27;50&#x27;)
     */
    pageSize?: number;

    /**
     * Id to use to get the previous page. If used, afterId should not be used.
     */
    beforeId?: string;

    /**
     * Id to get the next page, If used, beforeId should not be used.
     */
    afterId?: string;

    /**
     * Collection of filters that can be used. Filter value must be Base64 encoded. Example JSON: [{&#x27;name&#x27;:&#x27;Folder&#x27;,&#x27;value&#x27;:&#x27;Test Folder&#x27;},{&#x27;name&#x27;:&#x27;Client&#x27;,&#x27;value&#x27;:&#x27;b5838e48-f6b3-9104-6af9-4e96a1fb8d63&#x27;},{&#x27;name&#x27;:&#x27;Status&#x27;,&#x27;value&#x27;:&#x27;1&#x27;},{&#x27;name&#x27;:&#x27;Status&#x27;,&#x27;value&#x27;:&#x27;3&#x27;}]
     */
    filter?: string;

    /**
     * Which field to sort by
     */
    sort?: 'assignedTo';

    /**
     * Which order to sort by
     */
    order?: 'asc' | 'desc';
  }): Observable<StrictHttpResponse<ListPayrollFollowUpResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DataopsService.ListPayrollFollowUpPath, 'get');
    if (params) {
      rb.query('pageSize', params.pageSize, {});
      rb.query('beforeId', params.beforeId, {});
      rb.query('afterId', params.afterId, {});
      rb.query('filter', params.filter, {});
      rb.query('sort', params.sort, {});
      rb.query('order', params.order, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ListPayrollFollowUpResponse>;
      })
    );
  }

  /**
   * Gets a list of clients with payroll follow up needs.
   *
   * Returns a list of client payroll data based on sorting, paging, and filtering rules.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `listPayrollFollowUp$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listPayrollFollowUp(params?: {

    /**
     * How big each page should be (Default: &#x27;50&#x27;)
     */
    pageSize?: number;

    /**
     * Id to use to get the previous page. If used, afterId should not be used.
     */
    beforeId?: string;

    /**
     * Id to get the next page, If used, beforeId should not be used.
     */
    afterId?: string;

    /**
     * Collection of filters that can be used. Filter value must be Base64 encoded. Example JSON: [{&#x27;name&#x27;:&#x27;Folder&#x27;,&#x27;value&#x27;:&#x27;Test Folder&#x27;},{&#x27;name&#x27;:&#x27;Client&#x27;,&#x27;value&#x27;:&#x27;b5838e48-f6b3-9104-6af9-4e96a1fb8d63&#x27;},{&#x27;name&#x27;:&#x27;Status&#x27;,&#x27;value&#x27;:&#x27;1&#x27;},{&#x27;name&#x27;:&#x27;Status&#x27;,&#x27;value&#x27;:&#x27;3&#x27;}]
     */
    filter?: string;

    /**
     * Which field to sort by
     */
    sort?: 'assignedTo';

    /**
     * Which order to sort by
     */
    order?: 'asc' | 'desc';
  }): Observable<ListPayrollFollowUpResponse> {

    return this.listPayrollFollowUp$Response(params).pipe(
      map((r: StrictHttpResponse<ListPayrollFollowUpResponse>) => r.body as ListPayrollFollowUpResponse)
    );
  }

  /**
   * Path part for operation createPayrollFollowUpRecord
   */
  static readonly CreatePayrollFollowUpRecordPath = '/v2/dataops/payroll';

  /**
   * Adds payroll follow up record.
   *
   * Adds new payroll follow up record if it doesn't exist.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createPayrollFollowUpRecord()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createPayrollFollowUpRecord$Response(params: {
    body: CreatePayrollFollowUpRequest
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, DataopsService.CreatePayrollFollowUpRecordPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Adds payroll follow up record.
   *
   * Adds new payroll follow up record if it doesn't exist.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createPayrollFollowUpRecord$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createPayrollFollowUpRecord(params: {
    body: CreatePayrollFollowUpRequest
  }): Observable<void> {

    return this.createPayrollFollowUpRecord$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation importPayrollFollowUpRecords
   */
  static readonly ImportPayrollFollowUpRecordsPath = '/v2/dataops/payroll/import';

  /**
   * Import payroll follow up records from Excel or CSV.
   *
   * Import payroll follow up records from Excel or CSV.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `importPayrollFollowUpRecords$Csv()` instead.
   *
   * This method sends `text/csv` and handles request body of type `text/csv`.
   */
  importPayrollFollowUpRecords$Csv$Response(params: {
    body: string
  }): Observable<StrictHttpResponse<ImportPayrollFollowUpResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DataopsService.ImportPayrollFollowUpRecordsPath, 'put');
    if (params) {
      rb.body(params.body, 'text/csv');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ImportPayrollFollowUpResponse>;
      })
    );
  }

  /**
   * Import payroll follow up records from Excel or CSV.
   *
   * Import payroll follow up records from Excel or CSV.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `importPayrollFollowUpRecords$Csv$Response()` instead.
   *
   * This method sends `text/csv` and handles request body of type `text/csv`.
   */
  importPayrollFollowUpRecords$Csv(params: {
    body: string
  }): Observable<ImportPayrollFollowUpResponse> {

    return this.importPayrollFollowUpRecords$Csv$Response(params).pipe(
      map((r: StrictHttpResponse<ImportPayrollFollowUpResponse>) => r.body as ImportPayrollFollowUpResponse)
    );
  }

  /**
   * Import payroll follow up records from Excel or CSV.
   *
   * Import payroll follow up records from Excel or CSV.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `importPayrollFollowUpRecords$VndOpenxmlformatsOfficedocumentSpreadsheetmlSheet()` instead.
   *
   * This method sends `application/vnd.openxmlformats-officedocument.spreadsheetml.sheet` and handles request body of type `application/vnd.openxmlformats-officedocument.spreadsheetml.sheet`.
   */
  importPayrollFollowUpRecords$VndOpenxmlformatsOfficedocumentSpreadsheetmlSheet$Response(params: {
    body: string
  }): Observable<StrictHttpResponse<ImportPayrollFollowUpResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DataopsService.ImportPayrollFollowUpRecordsPath, 'put');
    if (params) {
      rb.body(params.body, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ImportPayrollFollowUpResponse>;
      })
    );
  }

  /**
   * Import payroll follow up records from Excel or CSV.
   *
   * Import payroll follow up records from Excel or CSV.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `importPayrollFollowUpRecords$VndOpenxmlformatsOfficedocumentSpreadsheetmlSheet$Response()` instead.
   *
   * This method sends `application/vnd.openxmlformats-officedocument.spreadsheetml.sheet` and handles request body of type `application/vnd.openxmlformats-officedocument.spreadsheetml.sheet`.
   */
  importPayrollFollowUpRecords$VndOpenxmlformatsOfficedocumentSpreadsheetmlSheet(params: {
    body: string
  }): Observable<ImportPayrollFollowUpResponse> {

    return this.importPayrollFollowUpRecords$VndOpenxmlformatsOfficedocumentSpreadsheetmlSheet$Response(params).pipe(
      map((r: StrictHttpResponse<ImportPayrollFollowUpResponse>) => r.body as ImportPayrollFollowUpResponse)
    );
  }

  /**
   * Import payroll follow up records from Excel or CSV.
   *
   * Import payroll follow up records from Excel or CSV.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `importPayrollFollowUpRecords$FormData()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  importPayrollFollowUpRecords$FormData$Response(params: {
    body: ImportPayrollFollowUpRecordMultipartRequest
  }): Observable<StrictHttpResponse<ImportPayrollFollowUpResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DataopsService.ImportPayrollFollowUpRecordsPath, 'put');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ImportPayrollFollowUpResponse>;
      })
    );
  }

  /**
   * Import payroll follow up records from Excel or CSV.
   *
   * Import payroll follow up records from Excel or CSV.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `importPayrollFollowUpRecords$FormData$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  importPayrollFollowUpRecords$FormData(params: {
    body: ImportPayrollFollowUpRecordMultipartRequest
  }): Observable<ImportPayrollFollowUpResponse> {

    return this.importPayrollFollowUpRecords$FormData$Response(params).pipe(
      map((r: StrictHttpResponse<ImportPayrollFollowUpResponse>) => r.body as ImportPayrollFollowUpResponse)
    );
  }

  /**
   * Path part for operation listPayrollFollowUpStatuses
   */
  static readonly ListPayrollFollowUpStatusesPath = '/v2/dataops/payroll/statuses';

  /**
   * Gets a list of distinct payroll Follow up statuses.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listPayrollFollowUpStatuses()` instead.
   *
   * This method doesn't expect any request body.
   */
  listPayrollFollowUpStatuses$Response(params?: {
  }): Observable<StrictHttpResponse<ListPayrollFollowUpStatusResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DataopsService.ListPayrollFollowUpStatusesPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ListPayrollFollowUpStatusResponse>;
      })
    );
  }

  /**
   * Gets a list of distinct payroll Follow up statuses.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `listPayrollFollowUpStatuses$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listPayrollFollowUpStatuses(params?: {
  }): Observable<ListPayrollFollowUpStatusResponse> {

    return this.listPayrollFollowUpStatuses$Response(params).pipe(
      map((r: StrictHttpResponse<ListPayrollFollowUpStatusResponse>) => r.body as ListPayrollFollowUpStatusResponse)
    );
  }

  /**
   * Path part for operation updatePayrollFollowUpAssignedTo
   */
  static readonly UpdatePayrollFollowUpAssignedToPath = '/v2/dataops/payroll/{clientId}/assignedto';

  /**
   * Updates a payroll follow up record assigned to value.
   *
   * Updates a payroll follow up record's assigned to value if it exists.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updatePayrollFollowUpAssignedTo()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updatePayrollFollowUpAssignedTo$Response(params: {

    /**
     * ClientId of the client to update assigned to.
     */
    clientId: string;
    body: UpdatePayrollFollowUpAssignedToRequest
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, DataopsService.UpdatePayrollFollowUpAssignedToPath, 'put');
    if (params) {
      rb.path('clientId', params.clientId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Updates a payroll follow up record assigned to value.
   *
   * Updates a payroll follow up record's assigned to value if it exists.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updatePayrollFollowUpAssignedTo$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updatePayrollFollowUpAssignedTo(params: {

    /**
     * ClientId of the client to update assigned to.
     */
    clientId: string;
    body: UpdatePayrollFollowUpAssignedToRequest
  }): Observable<void> {

    return this.updatePayrollFollowUpAssignedTo$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation updatePayrollFollowUpNote
   */
  static readonly UpdatePayrollFollowUpNotePath = '/v2/dataops/payroll/{clientId}/note';

  /**
   * Updates a payroll follow up record note.
   *
   * Updates a payroll follow up record's note if it exists.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updatePayrollFollowUpNote()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updatePayrollFollowUpNote$Response(params: {

    /**
     * ClientId of the client to update notes for.
     */
    clientId: string;
    body: UpdatePayrollFollowUpNoteRequest
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, DataopsService.UpdatePayrollFollowUpNotePath, 'put');
    if (params) {
      rb.path('clientId', params.clientId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Updates a payroll follow up record note.
   *
   * Updates a payroll follow up record's note if it exists.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updatePayrollFollowUpNote$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updatePayrollFollowUpNote(params: {

    /**
     * ClientId of the client to update notes for.
     */
    clientId: string;
    body: UpdatePayrollFollowUpNoteRequest
  }): Observable<void> {

    return this.updatePayrollFollowUpNote$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation updatePayrollFollowUpReviewedBy
   */
  static readonly UpdatePayrollFollowUpReviewedByPath = '/v2/dataops/payroll/{clientId}/reviewedby/{reviewedBy}';

  /**
   * Updates a payroll follow up record reviewed by value.
   *
   * Updates a payroll follow up record's reviewed by value if it exists.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updatePayrollFollowUpReviewedBy()` instead.
   *
   * This method doesn't expect any request body.
   */
  updatePayrollFollowUpReviewedBy$Response(params: {

    /**
     * ClientId of the client to update assigned to.
     */
    clientId: string;

    /**
     * Name of the person to review the client
     */
    reviewedBy: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, DataopsService.UpdatePayrollFollowUpReviewedByPath, 'put');
    if (params) {
      rb.path('clientId', params.clientId, {});
      rb.path('reviewedBy', params.reviewedBy, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Updates a payroll follow up record reviewed by value.
   *
   * Updates a payroll follow up record's reviewed by value if it exists.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updatePayrollFollowUpReviewedBy$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  updatePayrollFollowUpReviewedBy(params: {

    /**
     * ClientId of the client to update assigned to.
     */
    clientId: string;

    /**
     * Name of the person to review the client
     */
    reviewedBy: string;
  }): Observable<void> {

    return this.updatePayrollFollowUpReviewedBy$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation updatePayrollFollowUpStatus
   */
  static readonly UpdatePayrollFollowUpStatusPath = '/v2/dataops/payroll/{clientId}/status/{statusId}';

  /**
   * Updates a payroll follow up record's status.
   *
   * Updates a payroll follow up record if it exists.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updatePayrollFollowUpStatus()` instead.
   *
   * This method doesn't expect any request body.
   */
  updatePayrollFollowUpStatus$Response(params: {

    /**
     * ClientId of the client to update status.
     */
    clientId: string;

    /**
     * Status to change the client to
     */
    statusId: number;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, DataopsService.UpdatePayrollFollowUpStatusPath, 'put');
    if (params) {
      rb.path('clientId', params.clientId, {});
      rb.path('statusId', params.statusId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Updates a payroll follow up record's status.
   *
   * Updates a payroll follow up record if it exists.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updatePayrollFollowUpStatus$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  updatePayrollFollowUpStatus(params: {

    /**
     * ClientId of the client to update status.
     */
    clientId: string;

    /**
     * Status to change the client to
     */
    statusId: number;
  }): Observable<void> {

    return this.updatePayrollFollowUpStatus$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
