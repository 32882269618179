import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (!request.url.startsWith(`${environment.api.base}/`)) {
      return next.handle(request);
    }
    let headers = request.headers;
    if (request.headers.get('Content-Type') === '') {
      headers = request.headers.set('Content-Type', 'application/json');
    }

    headers = headers.set('Ocp-Apim-Subscription-Key', environment.services.subscriptionkey);

    // Get Function Key if it exist in the configs
    const functionKey: string | undefined = environment.apiKey;
    if (functionKey) {
      headers = headers.set('x-functions-key', functionKey);
    }

    return this.authService.getAccessTokenSilently().pipe(
      switchMap((token) => {
        if (token) {
          headers = headers.set('Authorization', `Bearer ${token}`);
        }
        return next.handle(
          request.clone({
            headers,
          })
        );
      })
    );
  }
}
