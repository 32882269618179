/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { ApiConfiguration } from '../api-configuration';
import { BaseService } from '../base-service';
import { RequestBuilder } from '../request-builder';
import { StrictHttpResponse } from '../strict-http-response';

import { ClientImpactPeriodResponse } from '../models/client-impact-period-response';
import { ClientLoanResponse } from '../models/client-loan-response';
import { ClientPaycodeResponse } from '../models/client-paycode-response';
import { ClientResponse } from '../models/client-response';
import { ClientSelectListResponse } from '../models/client-select-list-response';
import { ClientStatus } from '../models/client-status';
import { GetUndeliveredReasonAndNoteResponse } from '../models/get-undelivered-reason-and-note-response';
import { UpdateClientIdentifierResponse } from '../models/update-client-identifier-response';
import { UpdateUndeliveredReasonAndNoteRequest } from '../models/update-undelivered-reason-and-note-request';

@Injectable({
  providedIn: 'root',
})
export class ClientService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getClient
   */
  static readonly GetClientPath = '/v2/client/{clientId}';

  /**
   * Gets client info.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getClient()` instead.
   *
   * This method doesn't expect any request body.
   */
  getClient$Response(params: {

    /**
     * ClientId of the Client to retrieve.
     */
    clientId: string;
  }): Observable<StrictHttpResponse<ClientResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ClientService.GetClientPath, 'get');
    if (params) {
      rb.path('clientId', params.clientId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClientResponse>;
      })
    );
  }

  /**
   * Gets client info.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getClient$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getClient(params: {

    /**
     * ClientId of the Client to retrieve.
     */
    clientId: string;
  }): Observable<ClientResponse> {

    return this.getClient$Response(params).pipe(
      map((r: StrictHttpResponse<ClientResponse>) => r.body as ClientResponse)
    );
  }

  /**
   * Path part for operation getClientImpactPeriods
   */
  static readonly GetClientImpactPeriodsPath = '/v2/client/{clientId}/impactperiods';

  /**
   * Gets a client's impact period information.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getClientImpactPeriods()` instead.
   *
   * This method doesn't expect any request body.
   */
  getClientImpactPeriods$Response(params: {

    /**
     * ClientId of the client the impact periods will be returned for.
     */
    clientId: string;
  }): Observable<StrictHttpResponse<Array<ClientImpactPeriodResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, ClientService.GetClientImpactPeriodsPath, 'get');
    if (params) {
      rb.path('clientId', params.clientId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ClientImpactPeriodResponse>>;
      })
    );
  }

  /**
   * Gets a client's impact period information.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getClientImpactPeriods$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getClientImpactPeriods(params: {

    /**
     * ClientId of the client the impact periods will be returned for.
     */
    clientId: string;
  }): Observable<Array<ClientImpactPeriodResponse>> {

    return this.getClientImpactPeriods$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ClientImpactPeriodResponse>>) => r.body as Array<ClientImpactPeriodResponse>)
    );
  }

  /**
   * Path part for operation getClientLoans
   */
  static readonly GetClientLoansPath = '/v2/client/{clientId}/loans';

  /**
   * Gets a client's loan information.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getClientLoans()` instead.
   *
   * This method doesn't expect any request body.
   */
  getClientLoans$Response(params: {

    /**
     * ClientId of the client loans will be returned for.
     */
    clientId: string;
  }): Observable<StrictHttpResponse<Array<ClientLoanResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, ClientService.GetClientLoansPath, 'get');
    if (params) {
      rb.path('clientId', params.clientId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ClientLoanResponse>>;
      })
    );
  }

  /**
   * Gets a client's loan information.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getClientLoans$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getClientLoans(params: {

    /**
     * ClientId of the client loans will be returned for.
     */
    clientId: string;
  }): Observable<Array<ClientLoanResponse>> {

    return this.getClientLoans$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ClientLoanResponse>>) => r.body as Array<ClientLoanResponse>)
    );
  }

  /**
   * Path part for operation getClientPaycodes
   */
  static readonly GetClientPaycodesPath = '/v2/client/{clientId}/paycodes';

  /**
   * Gets a client's paycode information.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getClientPaycodes()` instead.
   *
   * This method doesn't expect any request body.
   */
  getClientPaycodes$Response(params: {

    /**
     * ClientId of the client the paycode information will be returned for.
     */
    clientId: string;
  }): Observable<StrictHttpResponse<Array<ClientPaycodeResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, ClientService.GetClientPaycodesPath, 'get');
    if (params) {
      rb.path('clientId', params.clientId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ClientPaycodeResponse>>;
      })
    );
  }

  /**
   * Gets a client's paycode information.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getClientPaycodes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getClientPaycodes(params: {

    /**
     * ClientId of the client the paycode information will be returned for.
     */
    clientId: string;
  }): Observable<Array<ClientPaycodeResponse>> {

    return this.getClientPaycodes$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ClientPaycodeResponse>>) => r.body as Array<ClientPaycodeResponse>)
    );
  }

  /**
   * Path part for operation getClientSelectList
   */
  static readonly GetClientSelectListPath = '/v2/clients/selectlist';

  /**
   * Gets a list of clients to be used in a select list.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getClientSelectList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getClientSelectList$Response(params?: {
  }): Observable<StrictHttpResponse<Array<ClientSelectListResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, ClientService.GetClientSelectListPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ClientSelectListResponse>>;
      })
    );
  }

  /**
   * Gets a list of clients to be used in a select list.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getClientSelectList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getClientSelectList(params?: {
  }): Observable<Array<ClientSelectListResponse>> {

    return this.getClientSelectList$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ClientSelectListResponse>>) => r.body as Array<ClientSelectListResponse>)
    );
  }

  /**
   * Path part for operation getUndeliveredReasonAndNote
   */
  static readonly GetUndeliveredReasonAndNotePath = '/v2/client/{clientId}/undeliveredreasonandnote';

  /**
   * Gets client undelivered credit reason and note.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUndeliveredReasonAndNote()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUndeliveredReasonAndNote$Response(params: {

    /**
     * ClientId of the Client to retrieve undelivered credit reason and note.
     */
    clientId: string;
  }): Observable<StrictHttpResponse<GetUndeliveredReasonAndNoteResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ClientService.GetUndeliveredReasonAndNotePath, 'get');
    if (params) {
      rb.path('clientId', params.clientId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GetUndeliveredReasonAndNoteResponse>;
      })
    );
  }

  /**
   * Gets client undelivered credit reason and note.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getUndeliveredReasonAndNote$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUndeliveredReasonAndNote(params: {

    /**
     * ClientId of the Client to retrieve undelivered credit reason and note.
     */
    clientId: string;
  }): Observable<GetUndeliveredReasonAndNoteResponse> {

    return this.getUndeliveredReasonAndNote$Response(params).pipe(
      map((r: StrictHttpResponse<GetUndeliveredReasonAndNoteResponse>) => r.body as GetUndeliveredReasonAndNoteResponse)
    );
  }

  /**
   * Path part for operation listAuditorsV2
   */
  static readonly ListAuditorsV2Path = '/v2/auditors';

  /**
   * Get a list of distinct Auditors.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listAuditorsV2()` instead.
   *
   * This method doesn't expect any request body.
   */
  listAuditorsV2$Response(params?: {
  }): Observable<StrictHttpResponse<Array<string>>> {

    const rb = new RequestBuilder(this.rootUrl, ClientService.ListAuditorsV2Path, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<string>>;
      })
    );
  }

  /**
   * Get a list of distinct Auditors.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `listAuditorsV2$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listAuditorsV2(params?: {
  }): Observable<Array<string>> {

    return this.listAuditorsV2$Response(params).pipe(
      map((r: StrictHttpResponse<Array<string>>) => r.body as Array<string>)
    );
  }

  /**
   * Path part for operation listCaresTeamsV2
   */
  static readonly ListCaresTeamsV2Path = '/v2/caresteams';

  /**
   * Get a list of distinct CARES Teams.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listCaresTeamsV2()` instead.
   *
   * This method doesn't expect any request body.
   */
  listCaresTeamsV2$Response(params?: {
  }): Observable<StrictHttpResponse<Array<string>>> {

    const rb = new RequestBuilder(this.rootUrl, ClientService.ListCaresTeamsV2Path, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<string>>;
      })
    );
  }

  /**
   * Get a list of distinct CARES Teams.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `listCaresTeamsV2$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listCaresTeamsV2(params?: {
  }): Observable<Array<string>> {

    return this.listCaresTeamsV2$Response(params).pipe(
      map((r: StrictHttpResponse<Array<string>>) => r.body as Array<string>)
    );
  }

  /**
   * Path part for operation listClientStatuses
   */
  static readonly ListClientStatusesPath = '/v2/clients/statuses';

  /**
   * Get a list of possible client statuses.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listClientStatuses()` instead.
   *
   * This method doesn't expect any request body.
   */
  listClientStatuses$Response(params?: {
  }): Observable<StrictHttpResponse<Array<ClientStatus>>> {

    const rb = new RequestBuilder(this.rootUrl, ClientService.ListClientStatusesPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ClientStatus>>;
      })
    );
  }

  /**
   * Get a list of possible client statuses.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `listClientStatuses$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listClientStatuses(params?: {
  }): Observable<Array<ClientStatus>> {

    return this.listClientStatuses$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ClientStatus>>) => r.body as Array<ClientStatus>)
    );
  }

  /**
   * Path part for operation listCpAsV2
   */
  static readonly ListCpAsV2Path = '/v2/cpas';

  /**
   * Get a list of distinct CPAs.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listCpAsV2()` instead.
   *
   * This method doesn't expect any request body.
   */
  listCpAsV2$Response(params?: {
  }): Observable<StrictHttpResponse<Array<string>>> {

    const rb = new RequestBuilder(this.rootUrl, ClientService.ListCpAsV2Path, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<string>>;
      })
    );
  }

  /**
   * Get a list of distinct CPAs.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `listCpAsV2$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listCpAsV2(params?: {
  }): Observable<Array<string>> {

    return this.listCpAsV2$Response(params).pipe(
      map((r: StrictHttpResponse<Array<string>>) => r.body as Array<string>)
    );
  }

  /**
   * Path part for operation listEiaApproversV2
   */
  static readonly ListEiaApproversV2Path = '/v2/eiaapprovers';

  /**
   * Get a list of distinct eia approvers.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listEiaApproversV2()` instead.
   *
   * This method doesn't expect any request body.
   */
  listEiaApproversV2$Response(params?: {
  }): Observable<StrictHttpResponse<Array<string>>> {

    const rb = new RequestBuilder(this.rootUrl, ClientService.ListEiaApproversV2Path, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<string>>;
      })
    );
  }

  /**
   * Get a list of distinct eia approvers.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `listEiaApproversV2$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listEiaApproversV2(params?: {
  }): Observable<Array<string>> {

    return this.listEiaApproversV2$Response(params).pipe(
      map((r: StrictHttpResponse<Array<string>>) => r.body as Array<string>)
    );
  }

  /**
   * Path part for operation listEiaWritersV2
   */
  static readonly ListEiaWritersV2Path = '/v2/eiawriters';

  /**
   * Get a list of distinct eia writers.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listEiaWritersV2()` instead.
   *
   * This method doesn't expect any request body.
   */
  listEiaWritersV2$Response(params?: {
  }): Observable<StrictHttpResponse<Array<string>>> {

    const rb = new RequestBuilder(this.rootUrl, ClientService.ListEiaWritersV2Path, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<string>>;
      })
    );
  }

  /**
   * Get a list of distinct eia writers.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `listEiaWritersV2$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listEiaWritersV2(params?: {
  }): Observable<Array<string>> {

    return this.listEiaWritersV2$Response(params).pipe(
      map((r: StrictHttpResponse<Array<string>>) => r.body as Array<string>)
    );
  }

  /**
   * Path part for operation listIrsNoticeStatusV2
   */
  static readonly ListIrsNoticeStatusV2Path = '/v2/irsnoticestatus';

  /**
   * Get a list of distinct IRS Notice Statuses.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listIrsNoticeStatusV2()` instead.
   *
   * This method doesn't expect any request body.
   */
  listIrsNoticeStatusV2$Response(params?: {
  }): Observable<StrictHttpResponse<Array<string>>> {

    const rb = new RequestBuilder(this.rootUrl, ClientService.ListIrsNoticeStatusV2Path, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<string>>;
      })
    );
  }

  /**
   * Get a list of distinct IRS Notice Statuses.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `listIrsNoticeStatusV2$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listIrsNoticeStatusV2(params?: {
  }): Observable<Array<string>> {

    return this.listIrsNoticeStatusV2$Response(params).pipe(
      map((r: StrictHttpResponse<Array<string>>) => r.body as Array<string>)
    );
  }

  /**
   * Path part for operation listPayrollPartnersV2
   */
  static readonly ListPayrollPartnersV2Path = '/v2/payrollpartners';

  /**
   * Get a list of distinct payroll partners.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listPayrollPartnersV2()` instead.
   *
   * This method doesn't expect any request body.
   */
  listPayrollPartnersV2$Response(params?: {
  }): Observable<StrictHttpResponse<Array<string>>> {

    const rb = new RequestBuilder(this.rootUrl, ClientService.ListPayrollPartnersV2Path, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<string>>;
      })
    );
  }

  /**
   * Get a list of distinct payroll partners.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `listPayrollPartnersV2$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listPayrollPartnersV2(params?: {
  }): Observable<Array<string>> {

    return this.listPayrollPartnersV2$Response(params).pipe(
      map((r: StrictHttpResponse<Array<string>>) => r.body as Array<string>)
    );
  }

  /**
   * Path part for operation listPayrollProvidersV2
   */
  static readonly ListPayrollProvidersV2Path = '/v2/payrollproviders';

  /**
   * Get a list of distinct payroll providers.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listPayrollProvidersV2()` instead.
   *
   * This method doesn't expect any request body.
   */
  listPayrollProvidersV2$Response(params?: {
  }): Observable<StrictHttpResponse<Array<string>>> {

    const rb = new RequestBuilder(this.rootUrl, ClientService.ListPayrollProvidersV2Path, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<string>>;
      })
    );
  }

  /**
   * Get a list of distinct payroll providers.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `listPayrollProvidersV2$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listPayrollProvidersV2(params?: {
  }): Observable<Array<string>> {

    return this.listPayrollProvidersV2$Response(params).pipe(
      map((r: StrictHttpResponse<Array<string>>) => r.body as Array<string>)
    );
  }

  /**
   * Path part for operation listReferralPartnersV2
   */
  static readonly ListReferralPartnersV2Path = '/v2/referralpartners';

  /**
   * Get a list of distinct referral partners.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listReferralPartnersV2()` instead.
   *
   * This method doesn't expect any request body.
   */
  listReferralPartnersV2$Response(params?: {
  }): Observable<StrictHttpResponse<Array<string>>> {

    const rb = new RequestBuilder(this.rootUrl, ClientService.ListReferralPartnersV2Path, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<string>>;
      })
    );
  }

  /**
   * Get a list of distinct referral partners.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `listReferralPartnersV2$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listReferralPartnersV2(params?: {
  }): Observable<Array<string>> {

    return this.listReferralPartnersV2$Response(params).pipe(
      map((r: StrictHttpResponse<Array<string>>) => r.body as Array<string>)
    );
  }

  /**
   * Path part for operation listSalesAssociatesV2
   */
  static readonly ListSalesAssociatesV2Path = '/v2/salesassociates';

  /**
   * Get a list of distinct sales associates.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listSalesAssociatesV2()` instead.
   *
   * This method doesn't expect any request body.
   */
  listSalesAssociatesV2$Response(params?: {
  }): Observable<StrictHttpResponse<Array<string>>> {

    const rb = new RequestBuilder(this.rootUrl, ClientService.ListSalesAssociatesV2Path, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<string>>;
      })
    );
  }

  /**
   * Get a list of distinct sales associates.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `listSalesAssociatesV2$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listSalesAssociatesV2(params?: {
  }): Observable<Array<string>> {

    return this.listSalesAssociatesV2$Response(params).pipe(
      map((r: StrictHttpResponse<Array<string>>) => r.body as Array<string>)
    );
  }

  /**
   * Path part for operation listTaxServiceManagersV2
   */
  static readonly ListTaxServiceManagersV2Path = '/v2/taxservicemanagers';

  /**
   * Get a list of distinct tax service managers.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listTaxServiceManagersV2()` instead.
   *
   * This method doesn't expect any request body.
   */
  listTaxServiceManagersV2$Response(params?: {
  }): Observable<StrictHttpResponse<Array<string>>> {

    const rb = new RequestBuilder(this.rootUrl, ClientService.ListTaxServiceManagersV2Path, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<string>>;
      })
    );
  }

  /**
   * Get a list of distinct tax service managers.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `listTaxServiceManagersV2$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listTaxServiceManagersV2(params?: {
  }): Observable<Array<string>> {

    return this.listTaxServiceManagersV2$Response(params).pipe(
      map((r: StrictHttpResponse<Array<string>>) => r.body as Array<string>)
    );
  }

  /**
   * Path part for operation listWotcProvidersV2
   */
  static readonly ListWotcProvidersV2Path = '/v2/wotcproviders';

  /**
   * Get a list of distinct WOTC providers.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listWotcProvidersV2()` instead.
   *
   * This method doesn't expect any request body.
   */
  listWotcProvidersV2$Response(params?: {
  }): Observable<StrictHttpResponse<Array<string>>> {

    const rb = new RequestBuilder(this.rootUrl, ClientService.ListWotcProvidersV2Path, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<string>>;
      })
    );
  }

  /**
   * Get a list of distinct WOTC providers.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `listWotcProvidersV2$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listWotcProvidersV2(params?: {
  }): Observable<Array<string>> {

    return this.listWotcProvidersV2$Response(params).pipe(
      map((r: StrictHttpResponse<Array<string>>) => r.body as Array<string>)
    );
  }

  /**
   * Path part for operation listWtsClientSpecialistsV2
   */
  static readonly ListWtsClientSpecialistsV2Path = '/v2/wtsclientspecialists';

  /**
   * Get a list of distinct wts client specialists.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listWtsClientSpecialistsV2()` instead.
   *
   * This method doesn't expect any request body.
   */
  listWtsClientSpecialistsV2$Response(params?: {
  }): Observable<StrictHttpResponse<Array<string>>> {

    const rb = new RequestBuilder(this.rootUrl, ClientService.ListWtsClientSpecialistsV2Path, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<string>>;
      })
    );
  }

  /**
   * Get a list of distinct wts client specialists.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `listWtsClientSpecialistsV2$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listWtsClientSpecialistsV2(params?: {
  }): Observable<Array<string>> {

    return this.listWtsClientSpecialistsV2$Response(params).pipe(
      map((r: StrictHttpResponse<Array<string>>) => r.body as Array<string>)
    );
  }

  /**
   * Path part for operation listWtsManagersV2
   */
  static readonly ListWtsManagersV2Path = '/v2/wtsmanagers';

  /**
   * Get a list of distinct wts managers.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listWtsManagersV2()` instead.
   *
   * This method doesn't expect any request body.
   */
  listWtsManagersV2$Response(params?: {
  }): Observable<StrictHttpResponse<Array<string>>> {

    const rb = new RequestBuilder(this.rootUrl, ClientService.ListWtsManagersV2Path, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<string>>;
      })
    );
  }

  /**
   * Get a list of distinct wts managers.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `listWtsManagersV2$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listWtsManagersV2(params?: {
  }): Observable<Array<string>> {

    return this.listWtsManagersV2$Response(params).pipe(
      map((r: StrictHttpResponse<Array<string>>) => r.body as Array<string>)
    );
  }

  /**
   * Path part for operation updateClientIdentifier
   */
  static readonly UpdateClientIdentifierPath = '/v2/client/{clientId}/identifier/{clientIdentifier}';

  /**
   * Updates a clients client identifier.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateClientIdentifier()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateClientIdentifier$Response(params: {

    /**
     * Client ID of the Client to update.
     */
    clientId: string;

    /**
     * Client identifier of the Client to update.
     */
    clientIdentifier: string;
  }): Observable<StrictHttpResponse<UpdateClientIdentifierResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ClientService.UpdateClientIdentifierPath, 'post');
    if (params) {
      rb.path('clientId', params.clientId, {});
      rb.path('clientIdentifier', params.clientIdentifier, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UpdateClientIdentifierResponse>;
      })
    );
  }

  /**
   * Updates a clients client identifier.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateClientIdentifier$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateClientIdentifier(params: {

    /**
     * Client ID of the Client to update.
     */
    clientId: string;

    /**
     * Client identifier of the Client to update.
     */
    clientIdentifier: string;
  }): Observable<UpdateClientIdentifierResponse> {

    return this.updateClientIdentifier$Response(params).pipe(
      map((r: StrictHttpResponse<UpdateClientIdentifierResponse>) => r.body as UpdateClientIdentifierResponse)
    );
  }

  /**
   * Path part for operation updateClientStatus
   */
  static readonly UpdateClientStatusPath = '/v2/clients/{clientId}/status/{status}';

  /**
   * Updates a clients status.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateClientStatus()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateClientStatus$Response(params: {

    /**
     * Client ID of the Client to update.
     */
    clientId: string;

    /**
     * Status to set the client to
     */
    status: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 14;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ClientService.UpdateClientStatusPath, 'put');
    if (params) {
      rb.path('clientId', params.clientId, {});
      rb.path('status', params.status, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Updates a clients status.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateClientStatus$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateClientStatus(params: {

    /**
     * Client ID of the Client to update.
     */
    clientId: string;

    /**
     * Status to set the client to
     */
    status: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 14;
  }): Observable<void> {

    return this.updateClientStatus$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation updateUndeliveredReasonAndNote
   */
  static readonly UpdateUndeliveredReasonAndNotePath = '/v2/clients/{clientId}/undeliveredreasonandnote';

  /**
   * Updates a clients undelivered reason and note.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateUndeliveredReasonAndNote()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateUndeliveredReasonAndNote$Response(params: {

    /**
     * Client ID of the Client to update undelivered reason and note.
     */
    clientId: string;
    body: UpdateUndeliveredReasonAndNoteRequest
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ClientService.UpdateUndeliveredReasonAndNotePath, 'put');
    if (params) {
      rb.path('clientId', params.clientId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Updates a clients undelivered reason and note.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateUndeliveredReasonAndNote$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateUndeliveredReasonAndNote(params: {

    /**
     * Client ID of the Client to update undelivered reason and note.
     */
    clientId: string;
    body: UpdateUndeliveredReasonAndNoteRequest
  }): Observable<void> {

    return this.updateUndeliveredReasonAndNote$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
